import React from "react";
import { useLocation } from "react-router-dom";

const PageNotFound = () => {
  let location = useLocation();
  location = location?.pathname?.split("/");
  console.log("loca", location?.pathname?.split("/"));
  return (
    <div className="pageNotFoundContainer d-flex flex-column justify-content-center align-items-center">
      <img alt="404" src={require("../assets/images/404.png")} />
      <h1 className="text-capitalize text-light fw-bold ">
        {location[1]} page not found
      </h1>
    </div>
  );
};

export default PageNotFound;
