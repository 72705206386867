import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import "./Nav.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useDispatch } from "react-redux";
import { onPersonalDetails } from "../Homepage/InvestRegisterSection/investRegisterSlice";
import { useSelector } from "react-redux";
import { CgProfile } from "react-icons/cg";
import { BiLogOutCircle } from "react-icons/bi";

const NavigationBar = () => {
  const user = JSON.parse(sessionStorage?.getItem("user"));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    sessionStorage.clear();

    window.location.href = "/";
  };

  let aadhaar_card_image = `https://brhfiles.s3.ap-south-1.amazonaws.com/${user?.aadhaar_card}`;

  let pan_card_image = `https://brhfiles.s3.ap-south-1.amazonaws.com/${user?.pan_card}`;

  let profile_image = user?.profile_image
    ? `https://brhfiles.s3.ap-south-1.amazonaws.com/${user?.profile_image}`
    : user?.gender === "MALE" || user?.gender === "Male"
    ? require("../../assets/images/MALEPROFILE.jpg")
    : require("../../assets/images/FEMALE1.jpg");

  const aadhaar_card_url = aadhaar_card_image;
  const pan_card_url = pan_card_image;
  const profile_url = profile_image;
  useEffect(() => {}, [user]);

  return (
    <div className="logoContainer" id="top">
      <Navbar
        collapseOnSelect={true}
        className="d-flex justify-content-around p-0 "
        bg="light"
        expand="lg"
      >
        <Container fluid className="p-0 navBarHead">
          <Nav className="me-auto col-md-3 col-sm-3 col-xs-3 text-center">
            <Navbar.Brand>
              <Link to="/">
                <img
                  className="logoImg"
                  src={require("../../assets/logos/logo.png")}
                  alt="logo"
                />
              </Link>
            </Navbar.Brand>
          </Nav>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            className="col-md-9 col-sm-2 col-xs-2 nav_container p-4"
            id="navbarScroll"
          >
            <Nav
              className="ms-auto my-lg-0 d-flex align-items-center w-100 justify-content-around"
              navbarScroll
            >
              <Link
                to="/home"
                className="text-white fw-bold f20 text-decoration-none"
              >
                Home
              </Link>
              <Link
                to="/our-projects"
                className="text-white fw-bold f20 text-decoration-none"
              >
                Our Projects
              </Link>
              <Link
                to="/faqs"
                className="text-white fw-bold f20 text-decoration-none"
              >
                FAQ's
              </Link>
              <Link
                to="/contact-us"
                className="text-white fw-bold f20 text-decoration-none"
              >
                Contact Us
              </Link>

              {/* <NavDropdown
                className="nav_link"
                title="Corporate"
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item>
                  <Link to="/home" className="text-dark text-decoration-none">
                    Corporates
                  </Link>
                </NavDropdown.Item>
              </NavDropdown> */}
              {user && (
                <>
                  <NavDropdown
                    className="nav_link"
                    title="Profile"
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item>
                      <Link
                        to="/user/profile"
                        className="text-dark text-decoration-none"
                      >
                        My Profile
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Link
                    to="/downloads"
                    className="text-white fw-bold f20 text-decoration-none"
                  >
                    Downloads
                  </Link>
                </>
              )}

              {!user ? (
                ""
              ) : (
                <NavDropdown
                  className="ms-md-5 d-flex justify-content-center align-items-center" //justify-content-center align-items-center
                  style={{ display: "flex", alignItems: "center" }}
                  title={
                    profile_url ? (
                      <>
                        <div className="d-flex justify-content-center align-items-center">
                          {/* d-flex justify-content-center align-items-center */}

                          <img
                            className="img-fluid rounded-circle "
                            style={{ width: "40px", height: "40px" }}
                            // rounded-circle userPic
                            alt="user pic"
                            // width={60}
                            // height={60}
                            src={profile_url}
                          />
                          <small className=" text-capitalize text-white fw-bold f16 text-decoration-none ms-2">
                            {user?.name}
                          </small>
                        </div>
                        {/* <small className="text-white text-capitalize userName">
                          {user?.name}
                        </small> */}
                      </>
                    ) : (
                      <img
                        src={require("../../assets/logos/ri_40.png")}
                        alt="user pic"
                        width={50}
                      />
                    )
                  }
                  id="navbarScrollingDropdown" //profileDropdown
                >
                  <NavDropdown.Item>
                    <Link
                      to="/user/profile"
                      className="text-dark text-decoration-none"
                    >
                      <CgProfile
                        style={{
                          fontSize: "18px",
                          marginRight: "10px",
                          color: "#faa61a",
                        }}
                      />
                      My Profile
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider style={{ borderColor: "#faa61a" }} />
                  <NavDropdown.Item onClick={handleLogout}>
                    <BiLogOutCircle
                      style={{
                        fontSize: "18px",
                        marginRight: "10px",
                        color: "#faa61a",
                      }}
                    />
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
