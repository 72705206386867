import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../../App.css";
import { useState } from "react";
import Profile from "./Profile";
import Address from "./Address";
import Nominee from "./Nominee";

function MyProfileComponent() {
  const activeKeyData = sessionStorage.getItem("profileTab");
  const [key, setKey] = useState(activeKeyData ?? "profile");

  const tabNames = [
    {
      id: 1,
      eventKey: "profile",
      title: "My Profile",
      component: <Profile />,
    },
    {
      id: 2,
      eventKey: "address",
      title: "My Address",
      component: <Address />,
    },
    {
      id: 3,
      eventKey: "nominee",
      title: "Nominee Details",
      component: <Nominee />,
    },
  ];

  const handleClick = (id) => {
    sessionStorage.setItem("profileTab", id);
  };

  return (
    <div className="parentContainer">
      <div className="investTab">
        <div className="a mt-4 mb-2 p-0">
          <Tabs
            activeKey={key}
            id="justify-tab-example"
            className="mb-3  "
            justify
            onSelect={(k) => {
              setKey(k);
              handleClick(k);
            }}
          >
            {tabNames.map((tab, index) => (
              <Tab
                key={index}
                tabClassName="rounded-0 primary "
                eventKey={tab.eventKey}
                title={tab.title}
              >
                {tab.component}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default MyProfileComponent;
