import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getMethod } from "../../API_SERVICES/ApiServices";

const DownloadForms = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [investData, setInvestData] = useState([]);
  const [investmentId, setInvsetmentID] = useState();
  const [investmentAgreement, setInvestmentAgreement] = useState();

  const [loading, setLoading] = useState(false);
  const download_url = "https://brhfiles.s3.ap-south-1.amazonaws.com/";


  useEffect(() => {

    getMethod(`api/user-investment/${investmentId}`).then((res) => {
      if (res?.data?.data) {
        setInvestmentAgreement(res?.data?.data[0]?.investment_agreement?.document)

      }
    })
  }, [investmentId])



  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    getMethod(`api/user/${user?.id}`, controller.signal).then((res) => {
      if (res?.data?.status === true) {
        // setInvestData(res?.data?.data?.user_investment);
        setInvestData(res?.data?.data);
        console.log(res?.data?.data);
        setLoading(false);
      }
    });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className="parentContainer">
      <div className="investTab">
        <span className="bgorange fw-bold fs-3 py-2 px-4">Download</span>

        <div className="a1 mt-4 mb-2 p-0">
          <Container className="p-5">
            <Row>
              <Col
                xs={12}
                sm={12}
                className="d-flex justify-content-between align-items-center my-2"
              >
                <span className="fs-4">LLP Partnership Agreement </span>
                {/* <Button variant="outline-warning rounded-0 ">Download</Button> */}
                <Button variant="outline-warning rounded-0 "><a href={`${download_url}` + investData?.llp_agreement} target="_blank" download style={{ textDecoration: "none", color: "inherit" }}>Download</a></Button>

              </Col>
            </Row>
            <hr style={{ borderColor: "orange" }} />
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className="d-flex justify-content-between align-items-center my-2"
              >
                <h4 className="fs-4 ">TDS Certificate </h4>
              </Col>
              <Col
                className="d-flex justify-content-between align-items-center my-2"
                xs={12}
                sm={12}
                md={6}
                lg={3}
              >
                <Form.Select className="rounded-0 mt-sm-2 mb-sm-2">
                  <option>Select Financial Year</option>
                </Form.Select>
              </Col>
              <Col
                className="d-flex justify-content-between align-items-center my-2"
                xs={12}
                sm={12}
                md={6}
                lg={3}
              >
                <Form.Select className="rounded-0 mt-sm-2 mb-sm-2">
                  <option>Select Quarter</option>
                </Form.Select>
              </Col>
              <Col
                className="d-flex justify-content-end align-items-center my-2"
                xs={12}
                sm={12}
                md={6}
                lg={3}
              >
                <Button variant="outline-warning rounded-0  ">Download</Button>
              </Col>
            </Row>
            <hr style={{ borderColor: "orange" }} />
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={3}
                className="d-flex  align-items-center my-2"
              >
                <h4 className="fs-4 ">Investment Agreement </h4>
              </Col>
              <Col
                className="d-flex align-items-center"
                xs={12}
                sm={12}
                md={6}
                lg={3}
              >
                <Form.Select onChange={(e) => setInvsetmentID(e.target.value)} className="rounded-0 mt-sm-2 mb-sm-2 my-2">
                  <option value=''>Select Investment Number</option>

                  {investData?.user_investment?.map((test) => (
                    <>
                      {/* <option>{test?.id}</option> */}
                      <option value={test?.id} >
                        {test?.investment_code}
                      </option>
                    </>
                  ))}

                </Form.Select>
              </Col>

              <Col
                className="d-flex justify-content-end align-items-center my-2"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Button variant="outline-warning rounded-0  "><a href={download_url + investmentAgreement} download style={{ textDecoration: "none", color: "inherit" }}>Download</a></Button>
              </Col>
            </Row>
            <hr style={{ borderColor: "orange" }} />

            <Row>
              <Col
                xs={12}
                sm={12}
                className="d-flex justify-content-between align-items-center my-2"
              >
                <span className="fs-4">Payment Schedule </span>
                <Button variant="outline-warning rounded-0  ">Download</Button>
              </Col>
            </Row>
            <hr style={{ borderColor: "orange" }} />
            <div className="buttonGroup mb-3">
              <Button
                className="d-flex justify-content-center align-items-center"
                style={{ height: "45px", width: "150px", fontSize: "25px" }}
                type="Button"
                onClick={() => alert("HEllo wait")}
                variant="outline-warning rounded-0 "
              >
                Close
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default DownloadForms;
