import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getData,
  getMethod,
  postMethod,
} from "../../../API_SERVICES/ApiServices";
import { onBankDetails, onNextPage } from "./investRegisterSlice";
import Modal from "react-bootstrap/Modal";
import { Fade, Slide } from "react-reveal";
import SupplementaryAgreement from "./SupplementaryAgreement";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
} from "docx";
const d = new Date();
const date = d.getDate();
const month = d.getMonth();
console.log(typeof month);
const year = d.getFullYear();
const MySwal = withReactContent(Swal);

const ReviewAndPay = ({ onNext, onPrevious }) => {
  const agreementsRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState({
    authError: null,
  });

  const [loading, setLoading] = useState(false);
  const [accept, setAccept] = useState({
    id1: false,
    id2: false,
    id3: false,
  });
  console.log("Location", location);
  const [trancheData, setTrancheData] = useState({});
  const investedDetailss = useSelector(
    (state) => state?.storedSates?.investmentDetails
  );
  const banks = useSelector((state) => state?.storedSates?.bankDetails);
  const nominees = useSelector((state) => state?.storedSates?.nomineeDetails);

  const person = useSelector((state) => state?.storedSates?.personalDetails);
  const activeKeyData = useSelector((state) => state?.storedSates?.activeTab);
  console.log("activeKeyData", activeKeyData);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const nominee = JSON.parse(sessionStorage.getItem("nominee"));
  const invested = JSON.parse(sessionStorage.getItem("investmentDetails"));
  const investment = JSON.parse(sessionStorage.getItem("investment"));
  const bank = JSON.parse(sessionStorage.getItem("bank"));
  const tranche = JSON.parse(sessionStorage.getItem("tranche"));
  const address = JSON.parse(sessionStorage.getItem("address"));
  const priceFormat = (price) => parseInt(price).toLocaleString("en-IN");

  const [investData, setInvestData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    getMethod(`api/user/${user?.id}`, controller.signal).then((res) => {
      if (res?.data?.status === true) {
        setInvestData(res?.data?.data?.user_investment);
        setLoading(false);
      }
    });

    return () => {
      controller.abort();
    };
  }, []);

  const table = new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(
                "Signed and delivered by the within named Existing Partners represented by Mr. Santhosh Mahalingam"
              ),
            ],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(
                `Signed and delivered by ${user?.name} as the “New Partners”`
              ),
            ],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [new Paragraph("")],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(`In the presence of (Witness Mr. Pavana Kumar)`),
            ],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(`In the presence of (Witness Mrs. Sandhya Vinod)`),
            ],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [new Paragraph("")],
          }),
        ],
      }),
    ],
  });
  const table1 = new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`New Partners Name`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`${user?.name}`)],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Effective Date`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(
                `${date.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${month.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${year}  `
              ),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Investment Date`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(
                `${date.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${month.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${year}  `
              ),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Capital Contribution`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Rs. 100/- `)],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Loan Contribution`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(
                `Loan Contribution 	TOTAL Rs.${priceFormat(
                  invested?.deposit_amount
                )}/- (Indian Rupees Only) as per the Investment Date mentioned above.`
              ),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Payment Frequency`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph(
                `${
                  invested?.interest_payment_id === 1
                    ? "Monthly"
                    : invested?.interest_payment_id === 2
                    ? "Quarterly"
                    : "Yearly"
                }`
              ),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Interest rate`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`12% - Simple interest balance method`)],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`Loan Term`)],
          }),
          new TableCell({
            width: {
              size: 5505,
              type: WidthType.DXA,
            },
            children: [new Paragraph(`${invested?.investment_period} months`)],
          }),
        ],
      }),
    ],
  });

  const generateFromUrl = async () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: "SUPPLEMENTARY LLP AGREEMENT",
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text:
                "This Supplementary LLP Agreement is made and executed at Bangalore on this the day " +
                `${date.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${month.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${year} `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: "BY",
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `Mr/Mrs/Ms ${
                " " + user?.name + " "
              } ,(S/o or W/o or D/o): ${
                " " + nominee?.nominee_name + ", "
              } currently residing at ${
                " " + address?.address + ", "
              }  PAN No : ${
                " " + user?.pan_card_number + " "
              } (here in after referred to as the “New Partner”, which expression shall,
                where the context admits, include his or heirs, executors and administrators)  `,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: "IN FAVOUR OF",
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: "All the existing partners of the LLP here in after referred to as the Existing Partners, which expression shall, where the context admits, include each of their respective heirs, executors and administrators and",
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `BRH Infra LLP,${
                " PAN No: " + user?.pan_card_number + " "
              } a limited liability partnership incorporated under the laws of India and having its registered office at 678, 2nd floor, Classic House, 17th Main, 6th A Cross Rd, 3rd Block, Koramangala, Bengaluru, Karnataka 560034.  (here in after referred to as the “LLP”, which expression shall, where the context admits, include its successors and permitted assigns)`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: "WHEREAS:",
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: ` i. The Existing Partners are parties to a limited liability partnership agreement dated thirteenth October Two Thousand Twenty-two (13/10/2022) (“LLP Agreement”), which sets out the terms and conditions pertaining to, inter alia, their inter se rights and obligations;`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: ` ii. Pursuant to discussions between the Designated Partners and the New Partner, the Designated Partners have agreed to induct the New Partner as a partner of the LLP; and`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: ` iii. The New Partner has agreed to be bound by the LLP Agreement and certain other terms set out herein by way of entering into this Deed.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `NOW THEREFORE THIS DEED WITNESSES AS FOLLOWS:`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `1) The New Partners shall bring in a contribution as stated in Schedule 1 to the LLP, in the manner as may be agreed between the Designated Partners and such New Partners and as required by the LLP. The contribution by the New Partners shall be utilized by the LLP for the purpose of conducting its general business, including but not limited to, paying deposits, carrying out interiors and developing infrastructure capabilities or for such other purpose as may be decided by the Designated Partners. Any loan provided by the partners is per Schedule – 2 of the said agreement. `,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `2) The New Partners shall be entitled to receive an interest for the loan provided to the LLP as per the loan agreement mutually agreed. All payments shall be subject to tax deduction at source (“TDS”) at applicable rates and the same shall be deposited with the appropriate authority within the applicable due date. TDS certificate will be issued within the timelines prescribed under Income Tax Act, 1961 and rules thereunder.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `3) It is hereby agreed by the parties that, except for the amount payable under Clause 2, the New Partners shall not be entitled to any claim or interest, goodwill revenue/profits, remuneration, other assets in LLP at any point of time in the LLP`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `4) Upon repayment of loan and payback of capital contribution of the New Partners, the Designated Partners shall be entitled to release the New Partners from the LLP by providing a prior written notice of 30 (thirty) days to the New Partners. The New Partners shall not be entitled to receive any additional benefits/rights / claim goodwill/ any other assets at the time of such retirement, except as defined in Clause 2.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `5) The New Partners cannot be expelled by the other Existing Partners, except where the New Partners has been found guilty of: 
              a.	carrying on the business of the LLP with a fraudulent purpose; and/or
              b.	acting in a way that is detrimental to the interest of the LLP or its business in any manner. 
              `,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `In such case, the New Partners may be removed as a partner of the LLP with the consent of the Designated Partners.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `6) In the event of death of the New Partners before the expiry of the Term, the LLP shall continue its business by admitting the nominee of the New Partners after obtaining the written consent of the said nominee. All terms of this Deed shall apply to the said nominee in the same manner as applicable to the New Partners. The details of the said nominee is as set out below:`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `${nominee?.nominee_name} and ${nominee?.address}`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `7) The New Partners shall be just and faithful to the LLP. The New Partners shall render to the Designated Partners true accounts and full information of all things affecting the LLP and shall attend to the beneficial working of the LLP.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `8) The New Partners shall at all times pay and discharge his separate and private debts and engagements whether present or future and keep the LLP and the Designated Partners indemnified therefrom and all other actions, proceedings, court claims and demands in respect thereof. It is hereby clarified that, the LLP shall not be responsible for the personal liabilities and/or loans of the New Partners. It is further clarified that, the New Partners shall not be responsible for the liabilities and/or loans   of the LLP and the LLP, along with the Designated Partners, shall keep the concerned New Partners indemnified from actions, proceedings, court claims and demands in respect thereof.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `9) In no event shall any party be liable for any special, punitive, indirect, consequential or incidental damages arising out of or relating to this Deed even if previously advised of the possibility of such damages and regardless of whether such liability arises out of contract, negligence, tort, strict liability or any other theory of legal liability; and in no event shall the cumulative liability of any party hereunder exceed the capital contribution amount paid by the concerned New Partners.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `10) The New Partners shall not assign, mortgage or charge his share in the LLP without the prior written consent of the Designated Partners.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `11) Subject to what is otherwise provided herein, the New Partners hereby accedes to the terms of the LLP Agreement and undertakes to the Existing Partners and the LLP that he shall observe, perform and be bound by the LLP Agreement in all respects as if the New Partners were an original party to the LLP Agreement with respect to the terms, conditions, obligations, covenants, representations and warranties and were named therein as a party`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `12) The New Partners hereby agrees and acknowledges that he shall not be entitled to be appointed as a designated partner of the LLP nor be involved in the day- to-day operations of the LLP.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `13) The New Partners acknowledges that this Deed shall be appended to the LLP Agreement and form a part thereof, and, by reference, incorporate and be governed by the terms thereof.`,
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: `IN WITNESS WHEREOF, this Deed has been executed as a deed on the day and year first above written.`,
            }),
            new Paragraph({
              text: ``,
            }),

            table,
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: "Schedule - 1",
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              pageBreakBefore: true,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: "Agreement Commercials",
              heading: HeadingLevel.HEADING_5,
              alignment: AlignmentType.CENTER,
            }),

            table1,
            new Paragraph({
              text: ``,
            }),

            new Paragraph({
              text: "SCHEDULE - 2",
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              pageBreakBefore: true,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: "SUPPLEMENTARY LOAN AGREEMENT ",
              heading: HeadingLevel.HEADING_3,
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `1.	The New Partners agrees to grant loan of INR ${priceFormat(
                invested?.deposit_amount
              )}/- (Indian Rupees only (“Principal”) to the LLP. The LLP agrees to repay the principal amount to the New Partners, with return on Principal payable on the unpaid principal at the rate of 12% percent per annum commencing from ${`${date.toLocaleString(
                "en-US",
                {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }
              )} / ${month.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })} / ${year} `}BY as detailed in Schedule I of this Agreement. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `2.	The LLP shall have the right (not an obligation) to prepay the outstanding Loan (whether in part or whole) at any point of time after 24 months from the commencement of the loan, during the term of repayment detailed in Schedule I, without any cost, penalty and/or charges of whatsoever nature. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `3.	This Agreement shall become effective on execution. The Agreement shall stand terminated on the date the LLP has repaid the Loan amount in full along with applicable and due return on Principal. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `4.	The New Partners provides the Loan amount to the LLP from his own funds. The Loan provided by New Partners to the LLP is an unsecured loan.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `5.	Notwithstanding anything to the contrary in this Agreement, if the LLP continuously defaults in the repayment of Loan amount for three months under this Agreement, then the New Partners may declare the principal amount owing and return on Principal due under this Agreement at that time to be immediately due and payable provided such default in payment is not cured within fifteen days from the date of notification of such default in writing to the LLP.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `6.	The New Partners shall not assign, either directly or indirectly, this Agreement to any third party. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `7.	All disputes, differences and/or claims, arising out of this Agreement, whether during its subsistence or thereafter, shall be settled by arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996 as may be in force from time to time. The sole arbitration will be mutually appointed by both Parties. The seat and venue of arbitration shall be at Chennai.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `8.	Subject to the Arbitration Clause mentioned above, this Agreement shall be governed and construed in accordance with the substantive laws of India and the parties hereto submit to the exclusive jurisdiction of the Courts at Chennai.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `9.	IN WITNESS WHEREOF, the parties have signed under hand and seal on this xx2.`,
            }),
            new Paragraph({
              text: ``,
            }),
            table,
          ],
        },
      ],
    });
    const doc1 = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: "SUPPLEMENTARY LOAN AGREEMENT ",
              heading: HeadingLevel.HEADING_3,
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `1.	The New Partners agrees to grant loan of INR ${priceFormat(
                invested?.deposit_amount
              )}/- (Indian Rupees only (“Principal”) to the LLP. The LLP agrees to repay the principal amount to the New Partners, with return on Principal payable on the unpaid principal at the rate of 12% percent per annum commencing from ${`${date.toLocaleString(
                "en-US",
                {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }
              )} / ${month.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })} / ${year} `}BY as detailed in Schedule I of this Agreement. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `2.	The LLP shall have the right (not an obligation) to prepay the outstanding Loan (whether in part or whole) at any point of time after 24 months from the commencement of the loan, during the term of repayment detailed in Schedule I, without any cost, penalty and/or charges of whatsoever nature. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `3.	This Agreement shall become effective on execution. The Agreement shall stand terminated on the date the LLP has repaid the Loan amount in full along with applicable and due return on Principal. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `4.	The New Partners provides the Loan amount to the LLP from his own funds. The Loan provided by New Partners to the LLP is an unsecured loan.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `5.	Notwithstanding anything to the contrary in this Agreement, if the LLP continuously defaults in the repayment of Loan amount for three months under this Agreement, then the New Partners may declare the principal amount owing and return on Principal due under this Agreement at that time to be immediately due and payable provided such default in payment is not cured within fifteen days from the date of notification of such default in writing to the LLP.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `6.	The New Partners shall not assign, either directly or indirectly, this Agreement to any third party. `,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `7.	All disputes, differences and/or claims, arising out of this Agreement, whether during its subsistence or thereafter, shall be settled by arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996 as may be in force from time to time. The sole arbitration will be mutually appointed by both Parties. The seat and venue of arbitration shall be at Chennai.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `8.	Subject to the Arbitration Clause mentioned above, this Agreement shall be governed and construed in accordance with the substantive laws of India and the parties hereto submit to the exclusive jurisdiction of the Courts at Chennai.`,
            }),
            new Paragraph({
              text: ``,
            }),
            new Paragraph({
              text: `9.	IN WITNESS WHEREOF, the parties have signed under hand and seal on this xx2.`,
            }),
            new Paragraph({
              text: ``,
            }),
            table,
          ],
        },
      ],
    });

    investData?.length > 0
      ? Packer.toBlob(doc1).then((blob) => {
          console.log(blob);
          saveAs(
            blob,
            `${
              user?.name + "_" + user?.pan_card_number + "_investment_agreement"
            }.docx`
          );
          console.log("Document created successfully");
        })
      : Packer.toBlob(doc).then((blob) => {
          console.log(blob);
          saveAs(
            blob,
            `${
              user?.name +
              "_" +
              user?.pan_card_number +
              "llp_investment_agreement"
            }.docx`
          );
          console.log("Document created successfully");
        });
  };

  const relationStatus = (status) => {
    switch (status) {
      case 1:
        return "Father";
        break;
      case 2:
        return "Mother";
        break;
      case 3:
        return "Brother";
        break;
      case 4:
        return "Sister";
        break;
      case 5:
        return "Spouse";
        break;

      default:
        break;
    }
  };

  //console.log("Location", location.pathname);

  useEffect(() => {
    const controller = new AbortController();
    console.log("Location", location.pathname);
    if (
      activeKeyData === "reviewPay" &&
      location?.pathname === "/new-investment"
    ) {
      tranche !== undefined ??
        getData("api/project-tranche", controller.signal)
          .then((res) => res.json())
          .then((result) => {
            setTrancheData(
              result?.data?.find((tr) => {
                if (tr.id === investment?.tranche_id) return tr;
              })
            );
            sessionStorage.setItem(
              "tranche",
              JSON.stringify(
                result?.data?.find((tr) => {
                  if (tr.id === investment?.tranche_id) return tr;
                })
              )
            );
            console.log(
              "Tranche",
              result?.data?.find((tr) => {
                if (tr.id === investment?.tranche_id) return tr;
              })
            );
          });
    }

    return () => {
      controller.abort();
    };
  }, [activeKeyData]);

  const personalDetails = [
    {
      label: "Name",
      details: user?.name?.toUpperCase(),
    },
    {
      label: "DOB",
      details: user?.dob,
    },
    {
      label: "PAN Number",
      details: user?.pan_card_number?.toUpperCase(),
    },
    {
      label: "AADHAAR CARD",
      details: user?.aadhar_card_number,
    },
    {
      label: "EMAIL",
      details: user?.email,
    },
    {
      label: "MOBILE NUMBER",
      details: user?.mobile_number,
    },
  ];

  const trancheDetails = [
    {
      label: "Tranche Code",
      details: tranche?.tranche_code,
    },
    {
      label: "Tranche Name",
      details: tranche?.tranche_name,
    },
    {
      label: "Tranche Total",

      details: "₹" + priceFormat(tranche?.investment_amount),
    },
    {
      label: "Balance Amount Required",

      details: "₹" + priceFormat(tranche?.balance_tranche_amount),
    },
  ];

  const nomineeDetails = [
    {
      label: "Name",
      details: nominee?.nominee_name?.toUpperCase(),
    },
    {
      label: "DOB",
      details: nominee?.dob,
    },
    {
      label: "Relationship",
      details: relationStatus(parseInt(nominee?.relationship_id)),
    },
  ];

  const investmentDetails = [
    {
      label: "Deposit Amount",
      details: "₹" + priceFormat(invested?.deposit_amount),
    },
    {
      label: "Profit Amount",
      details:
        "₹" +
        priceFormat(
          (parseInt(invested?.deposit_amount) *
            parseInt(invested?.investment_period)) /
            100
        ),
    },
    {
      label: "Profit Rate",
      details: "12%",
    },
    {
      label: "Investment Period",
      details: invested?.investment_period + " Months",
    },
    {
      label: "Profit Payment Frequency",
      details:
        invested?.interest_payment_id === 1
          ? "Monthly"
          : invested?.interest_payment_id === 2
          ? "Quarterly"
          : "Yearly",
    },
    {
      label: "Maturity Amount",
      details:
        "₹" +
        priceFormat(
          parseInt(invested?.deposit_amount) +
            (parseInt(invested?.deposit_amount) *
              parseInt(invested?.investment_period)) /
              100
        ),
    },
    {
      label: "LLP Capital Contribution ",
      details: "₹100 ",
    },
    {
      label: `${
        invested?.interest_payment_id === 1
          ? "Monthly  Profit Amount"
          : invested?.interest_payment_id === 2
          ? "Quarterly  Profit Amount"
          : "Yearly  Profit Amount"
      }`,
      details:
        location?.pathname === "/new-investment"
          ? "₹" +
            priceFormat(
              invested?.interest_payment ??
                (parseInt(invested?.deposit_amount) *
                  parseInt(invested?.investment_period)) /
                  100 /
                  invested?.investment_period
            )
          : "₹" + priceFormat(invested?.profit_amount),
    },
    {
      label: "RESIDENT",
      details: invested?.resident == 1 ? "RESIDENT" : "NON RESIDENT",
    },
  ];

  const bankDetails = [
    {
      label: "Account Holder Name",
      details: bank?.account_holder_name?.toUpperCase(),
    },
    {
      label: "Bank Name",
      details: bank?.bank_name?.toUpperCase(),
    },
    {
      label: "Branch",
      details: bank?.bank_branch?.toUpperCase(),
    },
    {
      label: "Account Number",
      details: bank?.account_number,
    },
    {
      label: "IFSC",
      details: bank?.ifsc?.toUpperCase(),
    },
  ];

  const previousPage = () => {
    dispatch(onNextPage("bankInvestmentDetails"));
    onPrevious("bankInvestmentDetails");
  };

  const onDepositSubmit = (e) => {
    if (accept?.id1 === true && accept?.id2 === true && accept?.id3 === true) {
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("tranche_id", tranche?.id);
      formData.append("deposit_amount", invested?.deposit_amount);
      formData.append("investment_period_id", invested?.investment_period_id);
      formData.append("interest_payment_id", invested?.interest_payment_id);
      formData.append("is_resident", 1);
      formData.append("address_id", address?.id);
      formData.append("nominee_id", nominee?.id);
      formData.append("bank_account_id", bank?.id);

      formData.append("status", 2);
      console.log(bank[0]);

      setLoading(true);
      postMethod("api/user-investment", formData)
        .then((res) => {
          console.log(res);
          if (res?.data?.status === true) {
            dispatch(onBankDetails(res?.data?.data));
            sessionStorage.setItem(
              "userInvestment",
              JSON.stringify(res?.data?.data)
            );
            setLoading(false);
            setTimeout(() => {
              requestForOTP();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err?.response?.data?.errors);
        });
    } else {
      if (accept?.id1 === true && accept?.id2 === true && accept?.id3 === false)
        MySwal.fire({
          html: <i>Accept LLP Agreement</i>,
          showConfirmButton: true,
          confirmButtonColor: "#fc9200",
          icon: "warning",
          allowOutsideClick: false,
        });
      else if (
        accept?.id1 === true &&
        accept?.id2 === false &&
        accept?.id3 === true
      )
        MySwal.fire({
          html: <i>Accept Terms & Conditions</i>,
          showConfirmButton: true,
          confirmButtonColor: "#fc9200",
          icon: "warning",
          allowOutsideClick: false,
        });
      else if (
        accept?.id1 === false &&
        accept?.id2 === true &&
        accept?.id3 === true
      )
        MySwal.fire({
          html: <i>Confirm Account Details</i>,
          showConfirmButton: true,
          confirmButtonColor: "#fc9200",
          icon: "warning",
          allowOutsideClick: false,
        });
      else
        MySwal.fire({
          html: <i>Check the fields before proceed</i>,
          showConfirmButton: true,
          confirmButtonColor: "#fc9200",
          icon: "warning",
          allowOutsideClick: false,
        });
    }
  };

  const nextPage = () => {
    dispatch(onNextPage("paymentDetails"));
  };

  const requestForOTP = () => {
    setLoading(true);
    postMethod("api/user-authenticate", {
      pan_card_number: user?.pan_card_number,
    })
      .then((res) => {
        if (res?.data?.status === true) {
          setTimeout(() => {
            setLoading(false);
            nextPage();
            onNext();
          }, 500);
        }
        if (res?.data?.status === false) {
          setTimeout(() => {
            setLoading(false);
            Swal.fire(res?.data?.message);
            setErrMsg({ ...errMsg, authError: res?.data?.message });
          }, 500);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.response?.data?.message);
      });
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h4 className="text-center"> Terms & Conditions</h4>
          <ol>
            <li>All payments should be made from your Indian bank accounts</li>
            <li>
              NRI's must pay from their NRO accounts or resident bank accounts.
              No transfer can be made from NRE accounts or foreign bank accounts
            </li>
            <li>ROI payments are subjected to TDS.</li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="warning" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  const [modalShow, setModalShow] = React.useState(false);
  function MyLLPAgreement(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h4 className="text-center">
            {investData?.length > 0
              ? "DRAFT INVESTMENT AGREEMENT"
              : "DRAFT LLP & INVESTMENT AGREEMENT"}
          </h4>
          <div style={{ height: "300px", overflowY: "scroll" }}>
            <Container ref={agreementsRef}>
              <SupplementaryAgreement />
            </Container>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div className="footer_disclaimer">
            <p
              className="footer_disclaimer_heading"
              style={{
                marginBottom: "1px",
                fontSize: " 10px",
                color: "#ffc107",
              }}
            >
              Note :
            </p>
            <p
              className="footer_disclaimer_cntnt"
              style={{
                marginBottom: "0",
                fontSize: " 10px",
                fontWeight: "600",
              }}
            >
              This a draft agreement document for your review. The originals
              will be send to you for your signature and uploaded to your
              profile.
            </p>
          </div>
          <Button
            className="rounded"
            variant="primary"
            onClick={generateFromUrl}
            type="button"
          >
            Download
          </Button>

          <Button type="button" variant="warning" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const [acceptModal, setAcceptModal] = React.useState(false);

  return (
    <Fade>
      <Container>
        <span className="bgorange orange_heading py-2 px-3">
          Personal Details
        </span>
        <hr style={{ marginTop: "5px", borderColor: "orange" }} />

        <Row className="my-4">
          {personalDetails?.map((details, index) => (
            <Col xs={6} sm={6} md={6} lg={4} xl={4}>
              <p className="orange_para">{details?.label}</p>
              <p className="orange_label">{details?.details}</p>
            </Col>
          ))}
        </Row>
        <span className="bgorange orange_heading py-2 px-3">
          Tranche Details
        </span>
        <hr style={{ marginTop: "5px", borderColor: "orange" }} />
        <Row className="my-4">
          {trancheDetails?.map((details, index) => (
            <Col xs={6} sm={6} md={6} lg={4} xl={4}>
              <p className="orange_para">{details?.label}</p>
              <p className="orange_label">{details?.details}</p>
            </Col>
          ))}
        </Row>
        <span className="bgorange orange_heading py-2 px-3">
          Nominee Details
        </span>
        <hr style={{ marginTop: "5px", borderColor: "orange" }} />
        <Row className="my-4">
          {nomineeDetails?.map((details, index) => (
            <Col xs={6} sm={6} md={6} lg={4} xl={4}>
              <p className="orange_para">{details?.label}</p>
              <p className="orange_label">{details?.details}</p>
            </Col>
          ))}
        </Row>
        <span className="bgorange orange_heading py-2 px-3">
          Investment Details
        </span>
        <hr style={{ marginTop: "5px", borderColor: "orange" }} />
        <Row className="my-4">
          {investmentDetails?.map((details, index) => (
            <Col xs={6} sm={6} md={6} lg={4} xl={4}>
              <p className="orange_para">{details?.label}</p>
              <p className="orange_label">{details?.details}</p>
            </Col>
          ))}
        </Row>
        <span className="bgorange orange_heading py-2 px-3">Bank Details</span>
        <hr style={{ marginTop: "5px", borderColor: "orange" }} />
        <Row className="my-4">
          {bankDetails?.map((details, index) => (
            <Col xs={6} sm={6} md={6} lg={4} xl={4}>
              <p className="orange_para">{details?.label}</p>
              <p className="orange_label">{details?.details}</p>
            </Col>
          ))}
        </Row>
        <h5 className="mt-3">
          <span className="fw-bold">Note:</span> Remittance should be done only
          from NRO or Resident Account. No remittance from NRE or overseas
          account permitted.
        </h5>
        <h5 className="mt-3">
          <span className="fw-bold">
            {invested?.interest_payment_id === 1
              ? "Monthly "
              : invested?.interest_payment_id === 2
              ? "Quarterly "
              : "Yearly "}{" "}
            Profit Amount: &nbsp;
          </span>
          <span className="orange fw-bold">
            {location?.pathname === "/new-investment"
              ? "₹" +
                priceFormat(
                  invested?.interest_payment ??
                    (parseInt(invested?.deposit_amount) *
                      parseInt(invested?.investment_period)) /
                      100 /
                      invested?.investment_period
                )
              : "₹" + priceFormat(invested?.profit_amount)}
          </span>
        </h5>
        <hr style={{ margin: "-2px", borderColor: "orange" }} />

        <div className="mt-3">
          <Form.Group className="d-flex align-items-sm-start  align-items-lg-center">
            <input
              onChange={() => setAccept({ ...accept, id1: !accept?.id1 })}
              className="mr-2"
              id="id1"
              type="checkbox"
            />
            <label style={{ marginLeft: "3px" }} htmlFor="id1">
              I hereby confirm that the account details provided here are
              correct and understand that the application is liable to be
              rejected in case of incorrect information
            </label>
          </Form.Group>
          <Form.Group className="d-flex align-items-sm-start  align-items-lg-center my-2">
            <input
              onChange={() => setAccept({ ...accept, id2: !accept?.id2 })}
              className="mr-2"
              id="id2"
              type="checkbox"
            />
            <label style={{ marginLeft: "3px" }} htmlFor="id2">
              I agree to the{" "}
              <a
                onClick={() => setAcceptModal(true)}
                style={{ color: "#fc9200" }}
                href="#"
              >
                terms and conditions
              </a>
            </label>
          </Form.Group>
          <Form.Group className="d-flex align-items-sm-start  align-items-lg-center">
            <input
              onChange={() => setAccept({ ...accept, id3: !accept?.id3 })}
              className="mr-2"
              id="id3"
              type="checkbox"
            />
            <label style={{ marginLeft: "3px" }} htmlFor="id3">
              I read and understood the{" "}
              <span
                className="text-decoration-underline"
                onClick={() => setModalShow(true)}
                style={{ color: "#fc9200" }}
                href="#"
              >
                {investData?.length > 0
                  ? "Investment Agreement"
                  : "LLP Agreement & Investment Agreement"}
              </span>
            </label>
          </Form.Group>
        </div>

        <div className="buttonGroup mb-3">
          <Button
            className="d-flex justify-content-center align-items-center commonBtn"
            type="button"
            onClick={previousPage}
            variant="outline-warning rounded-0"
          >
            <span className="mdi mdi-chevron-left commonIcon"></span>
            Previous
          </Button>

          <div className="border-1 orange d-flex py-2">
            <h5>
              <span>
                Total Amount to be Paid: &nbsp;
                {investData?.length > 0
                  ? ` ${"₹" + priceFormat(parseInt(invested?.deposit_amount))}`
                  : ` ${
                      "₹" +
                      priceFormat(
                        parseInt(invested?.deposit_amount + parseInt(100))
                      )
                    }`}
              </span>
            </h5>
          </div>

          <Button
            className="d-flex justify-content-center align-items-center commonBtn"
            onClick={onDepositSubmit}
            variant="warning rounded-0"
            disabled={loading}
            type="button"
          >
            Submit
            {loading ? (
              <Spinner
                style={{
                  fontSize: "28px",
                  fontWeight: "100",
                  marginLeft: "5px",
                }}
                size="sm"
                animation="border"
                variant="light"
              />
            ) : (
              <span className="mdi mdi-chevron-right commonIcon"></span>
            )}
          </Button>
        </div>
      </Container>
      <MyVerticallyCenteredModal
        show={acceptModal}
        onHide={() => setAcceptModal(false)}
      />
      <MyLLPAgreement show={modalShow} onHide={() => setModalShow(false)} />
    </Fade>
  );
};

export default ReviewAndPay;
