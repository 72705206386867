import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getMethod, postMethod } from "../../../API_SERVICES/ApiServices";
import FormComponent from "../FormComponent";
import { NormalTable } from "../../NormalTable";
import { Box, Button, Dialog } from "@mui/material";

const Nominee = () => {
  const nomineeDetails = JSON.parse(sessionStorage.getItem("nominee"));
  const user = JSON.parse(sessionStorage.getItem("user"));
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const investedDetails = useSelector(
    (state) => state?.storedSates?.investmentDetails
  );
  const userDetails = useSelector(
    (state) => state?.storedSates?.personalDetails
  );
  const [relationshipData, setRelationshipData] = useState({
    relation_name: null,
    relation_dob: null,
    relationship: null,
  });
  const [errors, setErrors] = useState({
    relation_name: null,
    relation_dob: null,
    relationship: null,
  });

  const [nomineeName, setNomineeName] = useState();
  const [nomineeDob, setNomineeDob] = useState();
  const [nomineeRelation, setNomineeRelation] = useState("");

  const [relationType, setRelationType] = useState([]);
  const [nomineeDetailsData, setNomineeDetailsData] = useState([]);
  const [relation, setRelation] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const activeKeyData = sessionStorage.getItem("profileTab");
  console.log("activeKeyData", activeKeyData);

  useEffect(() => {
    const controller = new AbortController();
    if (activeKeyData === "nominee") {
      getMethod("api/relationship", controller.signal).then((res) => {
        let data = [];
        res?.data?.data?.forEach((ele) => {
          ele.name = ele.relationship_name;
          data.push(ele);
        });

        setRelationType(data);
        data?.map((relation) => {
          if (relation?.id === nomineeDetails?.relationship_id) {
            return setRelation(relation?.relationship_name);
          }
        });
      });
    }

    return () => {
      controller.abort();
    };
  }, [activeKeyData, reload]);
  console.log(relationType);
  useEffect(() => {
    getMethod("api/user-nominee/" + user?.id)
      .then((res) => {
        if (res?.data?.status === true) {
          console.log(res.data.data, "data");
          const data = res?.data?.data.map((item) => {
            return {
              ...item,
              relationshiptype: relationType,
            };
          });
          console.log(data);
          setNomineeDetailsData(data);
        }
      })
      .catch((err) => console.log(err));
  }, [relationType]);
  // const formDetails = [
  //   {
  //     id: 1,
  //     formLabel: "Name (as it appears on PAN Card)",
  //     type: "text",
  //     placeHolder: "Name",
  //     name: "relation_name",
  //     required: true,
  //     isUpperCase: true,
  //     titleInfo: "Enter name as per PAN Card",
  //     error: errors.relation_name,
  //     disable: true,
  //     value: nomineeDetailsData?.map((nn) => nn?.nominee_name),
  //   },
  //   {
  //     id: 2,
  //     formLabel: "DOB (DD/MM/YYYY)",
  //     type: "date",
  //     placeHolder: "DD/MM/YYYY",
  //     name: "relation_dob",
  //     required: true,
  //     titleInfo: "Enter Date of Birth",
  //     error: errors.relation_dob,
  //     disable: true,
  //     value: nomineeDetails?.dob,
  //   },
  //   {
  //     id: 3,
  //     formLabel: "Relationship",
  //     type: "text",
  //     placeHolder: "Select Relationship",
  //     required: true,
  //     name: "relationship",
  //     option: relationType,
  //     titleInfo: "Select Relationship",
  //     error: errors.relationship,
  //     disable: true,
  //     value: relation,
  //   },
  // ];

  const formRef = useRef([]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setRelationshipData({ ...relationshipData, [name]: value });
  };

  console.log("------------------------------------ nominee");

  const [formsList, setFormsList] = useState([]);

  const [nomineeAddress, setNomineeAddress] = useState();

  const newNominee = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("nominee_name", nomineeName?.toUpperCase());
    formData.append("relationship_id", nomineeRelation);
    formData.append("dob", nomineeDob);
    formData.append("address", nomineeAddress);
    formData.append("status", 1);

    setTimeout(() => {
      setLoading(true);
      postMethod("api/user-nominee", formData)
        .then((res) => {
          if (res?.data?.status === true) {
            setLoading(false);
            //   setReload(true);
            //  window.location.reload();
            getMethod("api/user-nominee/" + user?.id)
              .then((res) => {
                if (res?.data?.status === true) {
                  console.log(res.data.data, "data");
                  const data = res?.data?.data.map((item) => {
                    return {
                      ...item,
                      relationshiptype: relationType,
                    };
                  });
                  console.log(data);
                  setNomineeDetailsData(data);
                  setOpenForm(false);
                }
              })
              .catch((err) => console.log(err));
            console.log(res?.data);
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log(err?.response?.data?.errors);
        });
    }, 500);
  };

  const addNominee = (event) => {
    setOpenForm(true);
  };
  const handleClose = (event) => {
    setOpenForm(false);
  };
  console.log(nomineeDetailsData, relation);
  const columns = useMemo(
    () => [
      {
        accessorKey: "nominee_name",
        header: "Client Name",

        size: 150,
      },
      // {
      //   accessorKey: 'property_id',
      //   header: 'Address',
      //   size: 200,
      // },
      {
        accessorKey: "dob",
        header: "Date of birth",

        size: 150,
      },

      {
        accessorKey: "address",
        header: "Address",

        size: 150,
      },
      {
        accessorKey: "relationship_id",
        header: "Relation",
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          console.log(rowData);

          const relationShip = rowData?.relationshiptype?.find(
            (item) => item.id === rowData.relationship_id
          );
          console.log(relationShip);
          return <span>{relationShip?.relationship_name}</span>;
        },
        size: 150,
      },
    ],
    []
  );
  return (
    <div className="investTab" id="nominee">
      <Container className=" rounded-0 mt-1">
        <Button
          onClick={addNominee}
          style={{ backgroundColor: "#fc9200" }}
          variant=" rounded-0"
        >
          Add Nominee
        </Button>

        <Dialog open={openForm} onClose={handleClose} maxWidth="md">
          <Box
            sx={{
              width: "100%",
              //   height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              rowGap: "16px",
              padding: "0px 50px",
            }}
          >
            <h1 className="pt-3" style={{ fontSize: "30px" }}>
              Add Nominee
            </h1>
            <form onSubmit={newNominee}>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="d-flex flex-column"
              >
                <Form.Label className="form-label">
                  Name (as it appears on PAN Card)
                  <span className="text-danger">*</span>
                </Form.Label>
                <input
                  onChange={(e) => setNomineeName(e.target.value)}
                  name="relation_name"
                  required
                  className="form-control rounded-0 form-label"
                  type="text"
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="d-flex flex-column"
              >
                <Form.Label className="form-label">
                  DOB (DD/MM/YYYY)
                  <span className="text-danger">*</span>
                </Form.Label>
                <input
                  onChange={(e) => setNomineeDob(e.target.value)}
                  name="relation_dob"
                  required
                  className="form-control rounded-0 form-label"
                  type="date"
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="d-flex flex-column"
              >
                <Form.Label className="form-label">
                  Address
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  onChange={(e) => setNomineeAddress(e.target.value)}
                  name="address"
                  className="form-control rounded-0 form-label"
                  as="textarea"
                  required
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="d-flex flex-column"
              >
                <Form.Label className="form-label">
                  Relationship
                  <span className="text-danger">*</span>
                </Form.Label>
                <select
                  onChange={(e) => setNomineeRelation(e.target.value)}
                  value={nomineeRelation}
                  name="relationship"
                  required
                  className="form-control rounded-0 form-label"
                >
                  {relationType?.map((relation, index) => (
                    <option value={relation?.id} key={index}>
                      {relation?.relationship_name}
                    </option>
                  ))}
                </select>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="d-flex flex-column pb-4"
              >
                <Button
                  type="submit"
                  variant="contained"
                  className="rounded-0 "
                  style={{ backgroundColor: "#fc9200" }}
                >
                  Add
                </Button>
              </Col>
            </form>

            {/* <SyncLoader color="#fc9200" /> */}
          </Box>
        </Dialog>
        <NormalTable columns={columns} data={nomineeDetailsData} />
      </Container>
    </div>
  );
};

export default Nominee;
