import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BankInvestmentDetails from "./BankInvestmentDetails";
import NomineeDetails from "./NomineeDetails";
import Payment from "./Payment";
import PersonalInformation from "./PersonalInformation";
import YourAddress from "./YourAddress";
import "../../../App.css";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InvestmentDetails from "./InvestmentDetails";
import ReviewAndPay from "./ReviewAndPay";
import { Slide } from "react-reveal";
import Report from "./Report";

function InvestRegistrationComponent() {
  const [key, setKey] = useState("personalInformation");
  const activeKeyData = useSelector((state) => state?.storedSates);
  //console.log("activeKeyData", activeKeyData);
  let location = useLocation();
  location = location.pathname.split("/");
  location = location[1];
  //console.log("Location", location);

  const withoutLogin = [
    {
      id: 1,
      eventKey: "personalInformation",
      title: "Personal Information",
      component: <PersonalInformation onNext={() => setKey("yourAddress")} />,
    },
    {
      id: 2,
      eventKey: "yourAddress",
      title: "Your Address",
      component: (
        <YourAddress
          onNext={() => setKey("nomineeDetails")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },
    {
      id: 3,
      eventKey: "nomineeDetails",
      title: "Nominee Details",
      component: (
        <NomineeDetails
          onNext={() => setKey("bankInvestmentDetails")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },
    {
      id: 4,
      eventKey: "bankInvestmentDetails",
      title: "Bank / Investment Details",
      component: (
        <BankInvestmentDetails
          onNext={() => setKey("reviewPay")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },

    {
      id: 5,
      eventKey: "reviewPay",
      title: "Review & Submit",
      component: (
        <ReviewAndPay
          onNext={() => setKey("paymentDetails")}
          onPrevious={(e) => setKey(e)}
        />
      ),
    },
    {
      id: 6,
      eventKey: "paymentDetails",
      title: "Payment Details",
      component: <Payment />,
    },
    {
      id: 6,
      eventKey: "Report",
      title: "Report",
      component: <Report />,
    },
  ];

  const withLogin = [
    {
      id: 1,
      eventKey: "personalInformation",
      title: "Investment Details",
      component: <InvestmentDetails onNext={() => setKey("nomineeDetails")} />,
    },
    {
      id: 2,
      eventKey: "nomineeDetails",
      title: "Nominee Details",
      component: (
        <NomineeDetails onNext={() => setKey("bankInvestmentDetails")} />
      ),
    },
    {
      id: 3,
      eventKey: "bankInvestmentDetails",
      title: "Bank Details",
      component: <BankInvestmentDetails onNext={() => setKey("reviewPay")} />,
    },
    {
      id: 5,
      eventKey: "reviewPay",
      title: "Review & Submit",
      component: <ReviewAndPay onNext={() => setKey("paymentDetails")} />,
    },
    {
      id: 6,
      eventKey: "paymentDetails",
      title: "Payment Details",
      component: <Payment />,
    },
  ];

  // const divRef = useRef();
  useEffect(() => {
    // divRef.current.scrollTo(0, 0);
    window.scrollTo(0, 0);
    // document.getElementById("root").scrollTo(0, 0);
    // window.scrollTo(0, 0);
    // console.log("--------------------------sheshank");
    // // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });
    //  console.log("main component---------------------------");
  });
  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log("user", user);
  let tabNames =
    location === "new-investment" && user?.id ? withLogin : withoutLogin;

  return (
    <div className="parentContainer">
      {/* ref={divRef} */}
      <div className="investTab">
        {location === "new-investment" && (
          <span className="bgorange fw-bold fs-3 py-2 px-3">
            NEW INVESTMENT
          </span>
        )}
        <div className="a mt-4 mb-2 p-0">
          <Tabs
            // activeKey={
            //   activeKeyData?.activeTab !== ""
            //     ? activeKeyData?.activeTab
            //     : "personalInformation"
            // }
            activeKey={key}
            //  activeKey={key}
            id="justify-tab-example"
            className="mb-3  "
            justify
            onSelect={(k) => setKey(k)}
          >
            {tabNames.map((tab, index) => (
              <Tab
                key={index}
                tabClassName="rounded-0 primary "
                eventKey={tab.eventKey}
                title={tab.title}
              >
                {tab.component}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default InvestRegistrationComponent;
