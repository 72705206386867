import React from "react";
import { Container } from "react-bootstrap";
import { Bounce, Fade, Reveal, Slide } from "react-reveal";

const OurProjects = () => {
  const projectsData = [
    {
      id: 1,
      imageUrl: require("../../assets/projects/bg1.jpg"),
      dataTitle:
        "21 CITIES | 33 CENTERS | 1000+ SEATS | 1000+ JOBS CREATED 125,000 SQ.FT. AND GROWING",
      statesLocations: [
        {
          id: 1,
          stateName: "TAMIL NADU",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "Chennai :",
                  branchLocation: "Ambattur | Guindy | Royappetta | Koyambedu ",
                },
                {
                  id: 2,
                  name: "Coimbatore :",
                  branchLocation:
                    "Lakshmi Mills Jn | Peelamedu | Ramanathapuram | Saibaba Colony ",
                },
                {
                  id: 3,
                  name: "SALEM : ",
                  branchLocation: "Omalur Road ",
                },
                {
                  id: 4,
                  name: "TIRUCHIRAPALLY : ",
                  branchLocation: "Karur Bypass | Thillai Nagar ",
                },
                {
                  id: 4,
                  name: "TIRUNELVELI  : ",
                  branchLocation: "MP Nagar ",
                },
                {
                  id: 5,
                  name: "AVADI    ",
                  branchLocation: [],
                },
                {
                  id: 5,
                  name: "CHENGALPETT",
                  branchLocation: [],
                },
                {
                  id: 5,
                  name: "MADURAI",
                  branchLocation: [],
                },
              ],
            },
          ],
        },
        {
          id: 2,
          stateName: "KERALA",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "KOCHI :",
                  branchLocation: "Kakkanad | Thrikkakkara | MG Road ",
                },

                {
                  id: 2,
                  name: "KOZHIKODE",
                  branchLocation: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      imageUrl: require("../../assets/projects/bg2.jpg"),
      dataTitle: "PRODUCTIVE WORKSPACES WITH ACCESS TO WORLD CLASS AMENITIES",
      statesLocations: [
        {
          id: 1,
          stateName: "KARNATAKA",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "BENGALURU :",
                  branchLocation: "Koramangala",
                },
                {
                  id: 2,
                  name: "MYSURU :",
                  branchLocation: "VV Mohalla",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          stateName: "MADHYA PRADESH",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "BHOPAL ",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          stateName: "MAHARASHTRA",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "MUMBAI :",
                  branchLocation: "Andheri",
                },
                {
                  id: 2,
                  name: "PUNE ",
                  branchLocation: "",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          stateName: "RAJASTHAN",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "JAIPUR :",
                  branchLocation: "Lalkoti",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,

      dataTitle: "CUSTOMISED AND BUDGET FRIENDLY SOLUTIONS ALSO AVAILABLE",
      imageUrl: require("../../assets/projects/bg3.jpg"),
      statesLocations: [
        {
          id: 1,
          stateName: "NEW DELHI",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "DELHI :",
                  branchLocation: "Connaught Place ",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          stateName: "UTTAR PRADESH",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "LUCKNOW :",
                  branchLocation: "Gomti Nagar ",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          stateName: "GUJARAT",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "AHMEDABAD :",
                  branchLocation: "Navrangpura ",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          stateName: "PUNJAB",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "MOHALI ",
                  branchLocation: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      imageUrl: require("../../assets/projects/bg4.jpg"),
      dataTitle:
        "CHANGING WORK CULTURE : FUTURE FRIENDLY COLLABORATIVE WORKSPACES",
      statesLocations: [
        {
          id: 1,
          stateName: "KARNATAKA",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "MICROBREWERIES :",
                  branchLocation: "",
                },
                {
                  id: 2,
                  name: "BENGALURU :",
                  branchLocation:
                    "KanakaPura | Electronic city | White Field  ",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          stateName: "KERALA",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "WoHo(Work from a Hotel) CENTER AT A 4 STAR HOTEL :",
                  branchLocation: "",
                },

                {
                  id: 2,
                  name: "THIRUVANANTHAPURAM :",
                  branchLocation: "Thampanoor",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          stateName: "UPCOMING CENTERS",
          dataLocations: [
            {
              branchName: [
                {
                  id: 1,
                  name: "HUBLI | BELGAUM | MANGALURU | THRISSUR | VISHAKAPATNAM | VIJAYAWADA | TIRUPATI | HYDERABAD | PUDUCHERRY | ERODE | HOSUR | VELLORE",
                  branchLocation: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <Slide bottom>
        {projectsData.map((projects, index) => (
          <div className="projectContainer">
            <img
              className="projectsImage"
              alt="workspaceImg"
              src={projects?.imageUrl}
            />
            <div className="hex3">
              <h3 className="text-center text-white fw-bold he">
                {projects?.dataTitle}
              </h3>
            </div>
            <div className="projectDataContainer">
              <div className="projectsDetails">
                <div className="projectInformation">
                  {projects?.statesLocations?.map((names, index) => (
                    <div className="text-center ">
                      <h3
                        style={{ color: "#dd8d19" }}
                        key={index}
                        className="m-2 "
                      >
                        {names?.stateName?.toUpperCase()}
                      </h3>
                      {names?.dataLocations?.map((data, index) => (
                        <div className="mb-2">
                          {data?.branchName?.map((d, index) => (
                            <>
                              <span className="" key={index}>
                                {d?.name} &nbsp;
                              </span>
                              <span className="">{d?.branchLocation}</span>
                              <br />
                            </>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <div className="projectsFooter">
          <Container className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img
                width={300}
                height={130}
                alt="footerLogo"
                src={require("../../assets/projects/mglogo.png")}
              />
            </div>
            <div>
              <div className="">
                <h3 className="m-2 d-inline fw-bold">
                  <i className="fab fa-whatsapp faIcons"></i> +91 80503 69875
                </h3>
                <h3 className="m-2 d-inline fw-bold">
                  <i class="fa-solid fa-envelope rounded-circle  faIcons  p-2"></i>
                  info@mikrografeio.com
                </h3>
              </div>
              <div className="">
                <h3 className="m-2 d-inline fw-bold">
                  <i className="fab fa-linkedin-in faIcons"></i>
                </h3>
                <h3 className="m-2 d-inline fw-bold">
                  <i class="fab fa-instagram  faIcons  p-2"></i>
                </h3>
                <h3 className="m-2 d-inline fw-bold">
                  <i className="fab fa-twitter faIcons"></i>
                </h3>
                <h3 className="m-2 d-inline fw-bold">
                  <i class="fab fa-facebook  faIcons  p-2"></i>
                </h3>
                <h3 className="m-2 d-inline fw-bold">
                  <i class="fab fa-youtube  faIcons  p-2"></i>
                </h3>
                <h3 className="m-2 d-inline fw-bold">@mikrografeio</h3>
              </div>
            </div>
          </Container>
        </div> */}
      </Slide>
    </div>
  );
};

export default OurProjects;
