import React, { useEffect, useMemo, useState } from "react";
import { getData, getMethod, postMethod } from "../../API_SERVICES/ApiServices";
import MaterialTable from "material-table";
import MaterialReactTable from "material-react-table";

import moment from "moment";
import { Container } from "react-bootstrap";

const MyInvestments = () => {
  const [investData, setInvestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const priceFormat = (str) => "₹" + parseInt(str).toLocaleString("en-IN");
  //console.log(investData);
  // let total = 0;

  // const totalInvestment = (investData) => {
  //   console.log(total + investData?.deposit_amount);
  // }
  // function myFunc(total, num) {
  //   return total - num;
  // }
  // console.log(investData?.reduce(myFunc));
  // function sum_reducer(accumulator, currentValue) {
  //   return accumulator + currentValue?.deposit_amount;
  // }
  const totalInvetmentAmount = investData?.reduce((acc, val) => {
    return acc + val.deposit_amount;
  }, 0);
  const totalProfitAmount = investData?.reduce((acc, val) => {
    return acc + val.total_profit_amount;
  }, 0);

  let totalProfitPaid = investData?.map((d) => d.new_investment_detail);
  totalProfitPaid = totalProfitPaid?.flatMap((e) => e);
  totalProfitPaid = totalProfitPaid?.reduce((acc, val) => {
    return acc + val.profit_released_amount;
  }, 0);

  const columns = useMemo(
    () => [
      {
        header: "Investment No.",
        Cell: ({ row }) => (
          <div className="fs-6">{row?.original?.investment_code}</div>
        ),
      },
      {
        header: "Investment Date",

        Cell: ({ row }) => (
          <div className="fs-6">
            {moment(row?.original?.start_date).format("Do MMM YYYY")}
          </div>
        ),
      },
      {
        header: "Tranche Number",

        Cell: ({ row }) => (
          <div className="fs-6">{row?.original?.tranche?.tranche_code}</div>
        ),
      },
      {
        header: "Investment Amount",
        Cell: ({ row }) => (
          <div className="fs-6">
            {priceFormat(row?.original?.deposit_amount)}
          </div>
        ),
      },
      {
        header: "Profit %",
        Cell: ({ row }) => <div className="fs-6">12% p.a</div>,
      },
      {
        header: "Investment Period",
        Cell: ({ row }) => (
          <div className="fs-6">
            {row?.original?.investment_period?.investment_period + " Months"}
          </div>
        ),
      },
      {
        header: "Profit Payment Frequency",
        Cell: ({ row }) => (
          <div className="fs-6">
            {row?.original?.interest_payment?.interest_profit_payment}
          </div>
        ),
      },
      {
        header: "Total Profit Amount",
        Cell: ({ row }) => (
          <div className="fs-6">
            {priceFormat(
              parseInt(row?.original?.deposit_amount) *
                0.12 *
                parseInt(
                  row?.original?.investment_period?.investment_period / 12
                )
            )}
          </div>
        ),
      },
      {
        header: "Inv Payment Status",
        Cell: ({ row }) => (
          <div>
            {row?.original?.status === 1 ? (
              <span className="text-success fs-6">Paid</span>
            ) : row?.original?.status === 2 ? (
              <span className="text-warning fs-6">Payment Pending</span>
            ) : row?.original?.status === 3 ? (
              <span className="text-warning fs-6">Approval Pending</span>
            ) : (
              <span className="text-warning fs-6">Cancelled</span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const profitColumns = useMemo(
    () => [
      {
        header: "Profit Date",

        Cell: ({ row }) => <div>{row?.original?.date}</div>,
      },
      // {
      //   header: "Financial Year",

      //   Cell: ({ row }) => <div>{row?.original?.finacial_year}</div>,
      // },
      {
        header: "Profit Amount",

        Cell: ({ row }) => (
          <div>{priceFormat(Math.round(row?.original?.sum_of_amount))}</div>
        ),
      },
      {
        header: "TDS Amount @10%",

        Cell: ({ row }) => (
          <div>
            {priceFormat(
              Math.round(row?.original?.sum_of_amount) * parseFloat(0.1)
            )}
          </div>
        ),
      },
      {
        header: "Profit Amount to Pay",
        Cell: ({ row }) => (
          <div>
            {priceFormat(
              Math.round(row?.original?.sum_of_amount) -
                Math.round(row?.original?.sum_of_amount) * parseFloat(0.1)
            )}
          </div>
        ),
      },
      {
        header: "UTR Number",
        Cell: ({ row }) => (
          <div>
            {row?.original?.utr_number === null ? (
              <span className="text-center fw-bold">-</span>
            ) : (
              <span>
                {row?.original?.transaction_number
                  ? (row?.original?.transaction_number).toUpperCase()
                  : "-"}
              </span>
            )}
          </div>
        ),
      },
      {
        header: "Payment Status",

        Cell: ({ row }) => (
          <div>
            {row?.original?.payment_status === 1 ? (
              <span className="text-success">Paid</span>
            ) : row?.original?.payment_status === 0 &&
              new Date(row?.original?.date) <= new Date() ? (
              <span className="text-warning">Due</span>
            ) : (
              <span className="text-warning">Not Due</span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    getMethod(`api/user/${user?.id}`, controller.signal).then((res) => {
      if (res?.data?.status === true) {
        setInvestData(res?.data?.data?.user_investment);
        setLoading(false);
      }
    });

    return () => {
      controller.abort();
    };
  }, []);

  //console.log("inve", investData);

  return (
    <div className="parentContainer">
      <div className="investTab">
        <span className="bgorange fw-bold fs-3 pt-2 pb-3 px-4">
          My Investments
        </span>

        <div className="a1 mt-4 mb-2 p-5">
          {loading ? (
            <Container className="text-center">
              <div class="lds-ring ">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Container>
          ) : (
            <>
              <div>
                <div className="row">
                  <div className="col-md-4">
                    <p className="myInvestmentTotalAmount">
                      total Investment Amount :{" "}
                      <span style={{ color: "limegreen" }}>
                        {priceFormat(totalInvetmentAmount)}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="myInvestmentTotalAmount">
                      total Profit Amount :{" "}
                      <span style={{ color: "limegreen" }}>
                        {priceFormat(totalProfitAmount)}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="myInvestmentTotalAmount">
                      total Profit Paid :{" "}
                      <span style={{ color: "limegreen" }}>
                        {priceFormat(totalProfitPaid)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <MaterialReactTable
                enableTopToolbar={false}
                initialState={{
                  pagination: { pageSize: 10 },
                  // density: "compact",
                }}
                defaultColumn={{
                  minSize: 85, //allow columns to get smaller than default
                  maxSize: 85, //allow columns to get larger than default
                  size: 85,
                }}
                columns={columns}
                data={investData}
                enableSorting={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableHiding={false}
                enableDensityToggle={false}
                renderDetailPanel={({ row }) => {
                  return (
                    <div className="m-0 expandInnerTable">
                      <MaterialReactTable
                        enableSorting={false}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableHiding={false}
                        enableDensityToggle={false}
                        initialState={{
                          pagination: { pageSize: 5 },
                          density: "compact",
                        }}
                        enableTopToolbar={false}
                        columns={profitColumns}
                        data={row?.original?.new_investment_detail?.filter(
                          (data) => data?.is_profit_release === 1
                        )}
                      />
                    </div>
                  );
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyInvestments;
