import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";
import {
  getData,
  getMethod,
  postMethod,
} from "../../../API_SERVICES/ApiServices";
import FormComponent from "../FormComponent";
import { onInvestmentDetails, onNextPage } from "./investRegisterSlice";

const InvestmentDetails = ({ onNext }) => {
  const [personalData, setPersonalData] = useState({
    deposit_amount: 0,
    tranche: null,
    resident: null,
    investment_period: null,
    interest_payment: null,
  });
  const [trancheDetails, setTrancheDetails] = useState();
  const [tranche, setTranche] = useState([]);
  const [errors, setErrors] = useState({
    deposit_amount: "Minimum Investment amount is ₹1,00,000",
    tranche: null,
    resident: null,
    investment_period: null,
    interest_payment: null,
  });
  console.log(
    "personalData?.deposit_amount",
    personalData?.deposit_amount === ""
  );
  const [investmentPeriodData, setInvestmentPeriodData] = useState([]);
  const [interestPeriod, setInterestPeriod] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statesData = useSelector((state) => state?.storedSates);

  const activeKeyData = useSelector((state) => state?.storedSates?.activeTab);
  const userDetails = useSelector(
    (state) => state?.storedSates?.personalDetails
  );
  const addressDetails = useSelector(
    (state) => state?.storedSates?.addressDetails
  );
  const landingScreenStates = useSelector(
    (state) => state?.landingScreenStates
  );

  const trancheStorage = JSON.parse(sessionStorage.getItem("tranche"));

  console.log("States", statesData);

  useEffect(() => {
    const controller = new AbortController();
    getData("api/investment-period")
      .then((res) => res.json())
      .then((result) => {
        const filterPeriod = result.data.filter((i) => i.status === 1);

        setInvestmentPeriodData(filterPeriod);
      });

    getData("api/interest-profit-payment")
      .then((res) => res.json())
      .then((result) => {
        setInterestPeriod(result.data.filter((i) => i.status === 1));
      });
    getData("api/project-tranche")
      .then((res) => res.json())
      .then((result) => {
        setTranche(result?.data?.filter((dat) => dat?.status === 1));
      });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (trancheDetails) {
      console.log("trancheDetails", trancheDetails);
      sessionStorage.setItem("tranche", JSON.stringify(trancheDetails));
    }
  }, [trancheDetails]);

  const priceFormat = (price) => parseInt(price).toLocaleString("en-IN");

  const onHandleChange = (e) => {
    const { name, value } = e.target;

    if (name === "tranche") {
      tranche?.map((el) => {
        console.log(el.id);
        return parseInt(el.id) === parseInt(value)
          ? setTrancheDetails(el)
          : value == ""
          ? setTrancheDetails(undefined)
          : null;
      });
    }

    setPersonalData({ ...personalData, [name]: value });
    setErrors({
      ...errors,
      investment_period: null,
    });
  };

  const nextPage = () => {
    setTimeout(() => {
      dispatch(onNextPage("nomineeDetails"));
    }, 300);
    onNext();
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const onSubmit = (e) => {
    e.preventDefault();
    const user = JSON.parse(sessionStorage.getItem("user"));
    const nominee = JSON.parse(sessionStorage.getItem("nominee"));
    const bank = JSON.parse(sessionStorage.getItem("bank"));
    const address = JSON.parse(sessionStorage.getItem("address"));
    const investment = JSON.parse(sessionStorage.getItem("investment"));
    if (!personalData.investment_period) {
      setErrors({ ...errors, investment_period: "Choose Investment Period" });
      return;
    }

    let data = { ...personalData };
    data.deposit_amount = parseInt(personalData.deposit_amount);
    data.interest_payment_id = parseInt(personalData.interest_payment);
    data.interest_payment = calcPriceFormat(personalData?.interest_payment);
    data.investment_period_id =
      parseInt(personalData.investment_period) === 24
        ? 1
        : parseInt(personalData.investment_period) === 36
        ? 2
        : parseInt(personalData.investment_period) === 48
        ? 3
        : 4;
    data.tranche_id = parseInt(personalData.tranche);
    data.is_resident = parseInt(personalData.resident);
    data.profit_amount = profitAmount;
    data.maturity_amount = maturityAmount;
    data.user_id = user?.id;
    data.address_id = address?.id;
    data.nominee_id = nominee?.id;
    data.bank_account_id = bank?.id;
    data.status = 1;

    console.log("Data", data);

    dispatch(onInvestmentDetails(data));
    sessionStorage.setItem("investmentDetails", JSON.stringify(data));
    // sessionStorage.setItem("investment", JSON.stringify(res?.data?.data));
    nextPage();
  };

  const amount = personalData?.deposit_amount;
  const investmentPeriod = personalData?.investment_period / 12;
  const intrestPayment = personalData?.interest_payment;

  const profitAmount = amount * 0.12 * investmentPeriod;

  const maturityAmount = parseInt(amount) + parseInt(profitAmount);

  useEffect(() => {
    console.log("Trance", trancheDetails);
  }, [trancheDetails]);
  const calcPriceFormat = function (id) {
    if (id == 1) {
      return (profitAmount / personalData?.investment_period) * 1;
    } else if (id == 2) {
      return (profitAmount / personalData?.investment_period) * 3;
    } else if (id == 3) {
      return (profitAmount / personalData?.investment_period / 2) * 24;
    } else {
      return 0;
    }
  };

  const [value, setValue] = useState(0);

  function handleInputChange(e) {
    const inputValue = e.target.value;
    setPersonalData({ ...personalData, deposit_amount: inputValue });
    setValue(inputValue);
  }

  return (
    <Fade>
      <Container>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <Form.Group>
                <Form.Label>
                  Enter Investment Amount <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="deposit_amount"
                  min={1000000}
                  autoComplete="off"
                  required
                  onKeyUp={(e) => {
                    if (e.target.value < 1000000) {
                      setErrors({
                        ...errors,
                        deposit_amount:
                          "Minimum Investment amount is ₹10,00,000",
                      });
                    } else {
                      setErrors({
                        ...errors,
                        deposit_amount: null,
                      });
                    }
                  }}
                  // max={trancheDetails?.balance_tranche_amount}
                  className="rounded-0 amount"
                  value={value}
                  onChange={handleInputChange}
                  type="number"
                />

                {errors?.deposit_amount === null && (
                  <small className="text-success">{`Investment Amount is ₹${priceFormat(
                    personalData?.deposit_amount
                  )}`}</small>
                )}
                {errors?.deposit_amount !== null && (
                  <small className="text-danger">
                    {errors?.deposit_amount}
                  </small>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Group>
                <Form.Label>
                  Select Tranche <span className="text-danger">*</span>
                </Form.Label>
                <select
                  onChange={onHandleChange}
                  name="tranche"
                  className="form-select rounded-0"
                  required
                >
                  <option value="">Select Tranche</option>
                  {tranche?.map((tran, index) => (
                    <option value={tran.id} key={index}>
                      {tran.tranche_name + " " + tran.tranche_code}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Form.Group className="formCheckLabel mt-4">
                <Form.Label className="d-block">
                  Resident Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  onChange={onHandleChange}
                  required
                  inline
                  label="Resident"
                  name="resident"
                  type="radio"
                  id="1"
                  value={1}
                />
                <Form.Check
                  onChange={onHandleChange}
                  required
                  inline
                  label="Non Resident"
                  name="resident"
                  type="radio"
                  id="2"
                  value={2}
                />
              </Form.Group>
            </Col>
            {trancheDetails !== undefined && (
              <Col xs={12} md={6} lg={6}>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <div>
                      <Form.Label>Project Names </Form.Label>
                      {trancheDetails?.projects?.map((project, index) => (
                        <small
                          key={index}
                          className="d-flex flex-column grey fw-300"
                        >
                          {project?.project_name}
                        </small>
                      ))}
                    </div>
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    <div>
                      <Form.Label>Total Tranche Amount</Form.Label>
                      <h5 className="grey fw-300">
                        ₹ {priceFormat(trancheDetails?.investment_amount)}
                      </h5>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <div>
                      <Form.Label>Balance Tranche Amount </Form.Label>
                      <h5 className="grey fw-300">
                        ₹
                        {priceFormat(
                          parseInt(trancheDetails?.balance_tranche_amount)
                        )}
                      </h5>
                    </div>
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    <div>
                      <Form.Label>Investment End Date</Form.Label>
                      <h5 className="grey fw-300">
                        {moment(trancheDetails?.funding_end_date).format(
                          "DD-MM-YYYY"
                        )}
                      </h5>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <hr />
          <Row>
            <Col xs={12} sm={12} lg={6}>
              <Form.Label>
                Select Investment period (in months)
                <span className="text-danger">*</span>
              </Form.Label>
              <div className="mt-2 d-flex align-items-center ">
                {investmentPeriodData.map((data, index) => (
                  <div className="inputGroup m-1">
                    <Form.Control
                      onChange={onHandleChange}
                      id={index + "" + data.investment_period}
                      name="investment_period"
                      type="radio"
                      value={data.investment_period}
                      onFocus={() => {
                        setErrors({
                          ...errors,
                          investment_period: null,
                        });
                      }}
                    />
                    <label htmlFor={index + "" + data.investment_period}>
                      {data.investment_period}
                    </label>
                  </div>
                ))}
              </div>
              {errors?.investment_period && (
                <span className="text-danger">{errors?.investment_period}</span>
              )}
            </Col>
            <Col xs={12} sm={12} lg={6}>
              <Row>
                <Form.Label>
                  Interest Payment <span className="text-danger">*</span>
                </Form.Label>
                <div className="row">
                  <Form.Group className="d-flex  formCheckLabel">
                    {interestPeriod?.map((profit, index) => (
                      <Form.Check
                        className="mx-2"
                        required
                        onChange={onHandleChange}
                        type="radio"
                        label={profit?.interest_profit_payment}
                        name="interest_payment"
                        value={profit?.id}
                      />
                    ))}
                  </Form.Group>
                </div>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className=" align-items-center">
            <Col>
              <Form.Label className="">Profit Rate</Form.Label>
            </Col>
            <Col>
              <span className="fs-1 fw-bold orange">12%p.a</span>
            </Col>
            <Col>
              <div>
                <Form.Label>Total Profit Amount</Form.Label>
                <h4 className="orange">
                  ₹
                  {personalData?.deposit_amount !== null
                    ? priceFormat(profitAmount)
                    : 0}
                </h4>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Label>
                  {" "}
                  {personalData?.interest_payment == 1
                    ? "Monthly"
                    : personalData?.interest_payment == 2
                    ? "Quaterly"
                    : personalData?.interest_payment == 3
                    ? "Annually"
                    : ""}{" "}
                  Profit Amount
                </Form.Label>
                <h4 className="orange">
                  ₹
                  {personalData?.deposit_amount !== null
                    ? // ? priceFormat(profitAmount)
                      priceFormat(
                        calcPriceFormat(personalData?.interest_payment)
                      ) // personalData?.deposit_amount * 0.12 * interestPeriod.id
                    : 0}
                </h4>
              </div>
            </Col>
            <Col>
              <div>
                <Form.Label>Maturity Amount</Form.Label>
                <h4 className="orange">
                  {/* ₹
                  {personalData?.deposit_amount !== null ||
                  personalData?.deposit_amount
                    ? priceFormat(maturityAmount)
                    : 0} */}
                  ₹
                  {personalData?.deposit_amount === "" ||
                  personalData?.deposit_amount === null
                    ? 0
                    : priceFormat(maturityAmount)}
                </h4>
              </div>
            </Col>
            {/* <Col>
            <div>
              <Form.Label>
                {personalData?.interest_payment == 12
                  ? "Monthly Payment"
                  : personalData?.interest_payment == 4
                  ? "Quarterly Payment"
                  : "Yearly Payment"}
              </Form.Label>
              <h4 className="orange">
                ₹
                {personalData?.deposit_amount !== null
                  ? priceFormat(repayAmount)
                  : 0}
              </h4>
            </div>
          </Col> */}
          </Row>
          <div className="buttonGroup mb-3">
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="button"
              onClick={() => navigate("/")}
              variant="outline-warning rounded-0"
            >
              Close
            </Button>
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="submit"
              variant="warning rounded-0"
            >
              Next
              <span className="mdi mdi-chevron-right commonIcon"></span>
            </Button>
          </div>
        </Form>
      </Container>
    </Fade>
  );
};

export default InvestmentDetails;
