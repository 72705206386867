import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getMethod,
  postMethod,
  updateMethod,
} from "../../../API_SERVICES/ApiServices";
import FormComponent from "../FormComponent";
import { MdModeEditOutline } from "react-icons/md";

const Address = () => {
  const [personalData, setPersonalData] = useState({
    address_id: null,
    user_id: null,
    door_no: null,
    street: null,
    country: null,
    state: null,
    district: null,
    city: null,
    pin_code: null,
    address_proof_type: null,
    upload_address_proof: null,

    cr_door_no: null,
    cr_street: null,
    cr_country: null,
    cr_state: null,
    cr_district: null,
    cr_city: null,
    cr_pin_code: null,
  });
  const [isEdit, setIsEdit] = useState(false);

  const [errors, setErrors] = useState({});
  const [countryDetails, setCountriesDetails] = useState([]);
  const [stateDetails, setStateDetails] = useState([]);
  const [cityDetails, setCityDetails] = useState([]);
  const [crstateDetails, setcrStateDetails] = useState([]);
  const [crcityDetails, setcrCityDetails] = useState([]);
  const [addressType, setAddressType] = useState([]);
  const [sameAddress, setSameAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const investedDetails = useSelector(
    (state) => state?.storedSates?.investmentDetails
  );

  const activeKeyData = useSelector((state) => state?.storedSates?.activeTab);
  const userDetails = useSelector(
    (state) => state?.StoredState?.personalDetails
  );
  console.log(userDetails);
  const user = sessionStorage.getItem("user");

  useEffect(() => {
    const controller = new AbortController();

    getMethod("api/country", controller.signal).then((res) => {
      setCountriesDetails(res?.data?.data);
    });
    getMethod("api/state/" + personalData?.country, controller.signal).then(
      (res) => {
        setStateDetails(res?.data?.data);
      }
    );
    getMethod("api/city/" + personalData?.state, controller.signal).then(
      (res) => {
        setCityDetails(res?.data?.data);
      }
    );
    getMethod("api/state/" + personalData?.cr_country, controller.signal).then(
      (res) => {
        console.log("called", res.data.data);
        setcrStateDetails(res?.data?.data);
      }
    );
    getMethod("api/city/" + personalData?.cr_state, controller.signal).then(
      (res) => {
        setcrCityDetails(res?.data?.data);
      }
    );

    return () => {
      controller.abort();
    };
  }, [personalData]);
  console.log(isEdit);
  useEffect(() => {
    const controller = new AbortController();
    if (personalData?.pin_code !== null) {
      getMethod("api/address-document", controller.signal).then((res) => {
        let data = [];
        res?.data?.data?.forEach((ele) => {
          ele.name = ele.address_document_name;
          data.push(ele);
        });

        setAddressType(data);
      });
    }

    return () => {
      controller.abort();
    };
  }, [personalData?.pin_code]);

  const addressDetails = JSON.parse(sessionStorage.getItem("address"));
  // const { upload_address_proof } = personalData;
  // const parts = upload_address_proof.split("\\"); // Split the string by backslashes

  // // The file name is the last part of the split
  // const fileName = parts[parts.length - 1];
  const permanentAddress = [
    {
      id: 1,
      formLabel: "Door | Street ",
      type: "text",
      placeHolder: "Name",
      required: true,
      name: "door_no",
      value: personalData?.door_no,
      readonly: !isEdit ? true : false,
    },
    // {
    //   id: 2,
    //   formLabel: "Street | Area | Landmark",
    //   type: "text",
    //   placeHolder: "Street",
    //   required: true,
    //   name: "street",
    //   value: addressDetails?.address_1,
    //
    //      disable
    // : !isEdit ? true : false,

    // },
    {
      id: 3,
      formLabel: "Country",
      type: "select",
      placeHolder: "county",
      required: true,
      name: "country",
      value: personalData?.country,
      option: countryDetails,
      readonly: !isEdit ? true : false,
    },
    {
      id: 4,
      formLabel: "State",
      type: "select",
      placeHolder: "State ",
      required: true,
      name: "state",
      value: personalData?.state,
      option: stateDetails,
      readonly: !isEdit ? true : false,
    },
    {
      id: 5,
      formLabel: "District",
      type: "text",
      placeHolder: "District",
      required: true,
      name: "district",
      value: personalData?.district ?? "--",
      readonly: !isEdit ? true : false,
    },
    {
      id: 6,
      formLabel: "City | Town | Village",
      type: "select",
      placeHolder: "Gender",
      required: true,
      name: "city",
      value: personalData?.city,
      option: cityDetails,
      readonly: !isEdit ? true : false,
    },
    {
      id: 7,
      formLabel: "PIN Code",
      type: "text",
      placeHolder: "PIN Number",
      required: true,
      name: "pin_code",
      value: personalData?.pin_code,
      readonly: !isEdit ? true : false,
    },
    {
      id: 8,
      formLabel: "Address Proof Type",
      type: "select",
      placeHolder: "Upload Photo",
      required: true,
      name: "address_proof_type",
      value: personalData?.address_proof_type,
      option: addressType,
      readonly: !isEdit ? true : false,
    },
    {
      id: 9,
      formLabel: "Upload Address Proof",
      type: "file",
      placeHolder: "Upload Address",
      required: true,
      name: "upload_address_proof",
      filevalue: personalData?.upload_address_proof,
      readonly: !isEdit ? true : false,
      info: (
        <i>
          <span className="mdi mdi-information-outline text-warning"></span>
          Supported file type png, jpg, pdf of max size: 2mb
        </i>
      ),
    },
  ];
  const correspondenceAddress = [
    {
      id: 1,
      formLabel: "Door | Street ",
      type: "text",
      placeHolder: "Name",
      required: true,
      name: "cr_door_no",
      value: personalData?.cr_door_no,
      readonly: !isEdit ? true : false,
    },
    // {
    //   id: 2,
    //   formLabel: "Street | Area | Landmark",
    //   type: "text",
    //   placeHolder: "DD/MM/YYYY",
    //   required: true,
    //   name: "cr_street",
    //   value: personalData?.cr_street,
    //                 disable

    // : !isEdit ? true : false,

    // },
    {
      id: 3,
      formLabel: "Country",
      type: "select",
      placeHolder: "county",
      required: true,
      name: "cr_country",
      value: personalData?.cr_country,
      option: countryDetails,
      readonly: !isEdit ? true : false,
    },
    {
      id: 4,
      formLabel: "State",
      type: "select",
      placeHolder: "State",
      required: true,
      name: "cr_state",
      value: personalData?.cr_state,
      option: crstateDetails,
      readonly: !isEdit ? true : false,
    },
    {
      id: 5,
      formLabel: "District",
      type: "text",
      placeHolder: "PAN Number",
      required: true,
      name: "cr_district",
      value: personalData?.cr_district ?? "--",
      readonly: !isEdit ? true : false,
    },
    {
      id: 6,
      formLabel: "City | Town | Village",
      type: "select",
      placeHolder: "Gender",
      required: true,
      name: "cr_city",
      value: personalData?.cr_city,
      option: crcityDetails,
      readonly: !isEdit ? true : false,
    },
    {
      id: 7,
      formLabel: "PIN Code",
      type: "text",
      placeHolder: "AAADHAAR Number",
      required: true,
      name: "cr_pin_code",
      value: personalData?.cr_pin_code,
      readonly: !isEdit ? true : false,
    },
  ];
  console.log("pes", personalData);
  const onHandleChange = (e) => {
    const controller = new AbortController();
    const { name, value, files } = e.target;
    console.log(name, value);

    setPersonalData({ ...personalData, [name]: value });
    setErrors({ ...errors, investment_period: null });
    if (name === "country") {
      getMethod("api/state/" + value, controller.signal).then((res) => {
        setStateDetails(res?.data?.data);
      });
    }
    if (name === "state") {
      getMethod("api/city/" + value, controller.signal).then((res) => {
        setCityDetails(res?.data?.data);
      });
    }
    if (name === "cr_country") {
      getMethod("api/state/" + value, controller.signal).then((res) => {
        setcrStateDetails(res?.data?.data);
      });
    }
    if (name === "cr_state") {
      getMethod("api/city/" + value, controller.signal).then((res) => {
        setcrCityDetails(res?.data?.data);
      });
    }
  };

  const formRef = useRef([]);

  const onSubmit = (e) => {
    e.preventDefault();
    let data = { ...personalData };
    data.address_proof_type = parseInt(personalData.address_proof_type);
    data.city = parseInt(personalData.city);
    data.state = parseInt(personalData.state);
    data.country = parseInt(personalData.country);
    data.cr_city = parseInt(personalData.cr_city);
    data.cr_state = parseInt(personalData.cr_state);
    data.cr_country = parseInt(personalData.cr_country);
    console.log("shhsdhdh", data);
    const formData = new FormData();
    formData.append("user_id", data?.user_id);
    formData.append("_method", "PUT");
    formData.append("address_document_id", data?.address_proof_type);
    formData.append("address_document", data?.upload_address_proof);
    formData.append("address", data?.door_no);
    formData.append("country_id", data?.country);
    formData.append("state_id", data?.state);
    formData.append("district", data?.district);
    formData.append("city_id", data?.city);
    formData.append("postal_code", data?.pin_code);
    formData.append("is_correspondance_address", data?.address_proof_type);
    formData.append("address_1", data?.cr_door_no);
    formData.append("country_id_1", data?.cr_country);
    formData.append("state_id_1", data?.cr_state);
    formData.append("district_1", data?.cr_district);
    formData.append("city_id_1", data?.cr_city);
    formData.append("postal_code_1", data?.cr_pin_code);
    formData.append("status", 1);
    // const formData = {
    //   user_id: data?.user_id,
    //   //_method: "PUT",
    //   // address_document_id: data?.address_proof_type,
    //   // address_document_file: data?.upload_address_proof,
    //   address: data?.door_no,
    //   country_id: data?.country,
    //   state_id: data?.state,
    //   district: data?.district,
    //   city_id: data?.city,
    //   postal_code: data?.pin_code,
    //   // is_correspondance_address: data?.address_proof_type,
    //   address_1: data?.cr_door_no,
    //   country_id_1: data?.cr_country,
    //   state_id_1: data?.cr_state,
    //   district_1: data?.cr_district,
    //   city_id_1: data?.cr_city,
    //   postal_code_1: data?.cr_pin_code,
    //   status: 1,
    // };
    setTimeout(() => {
      setLoading(true);
      postMethod(`api/user-address/${personalData.address_id}`, formData)
        .then((res) => {
          console.log(res);
          if (res?.data?.status === true) {
            getMethod(`api/user-address/${addressDetails?.user_id}`).then(
              (res) => {
                const data = res.data.data[0];
                console.log("data", data);
                const addresstypename = addressType.find(
                  (i) => i.id === data.address_document_id
                );
                console.log("addname", addresstypename);
                setPersonalData((prev) => ({
                  ...prev,
                  address_id: data.id,
                  user_id: data.user_id,
                  door_no: data.address,
                  pin_code: data.postal_code,
                  country: data.country_id,
                  city: data.city_id,
                  state: data.state_id,
                  district: data.district,
                  cr_door_no: data.address,
                  cr_pin_code: data.postal_code,
                  cr_country: data.country_id_1,
                  cr_city: data.city_id_1,
                  cr_state: data.state_id_1,
                  cr_district: data.district_1,
                  address_proof_type: data.address_document_id,
                  upload_address_proof: data.address_document,
                }));
                setIsEdit(false);
                setLoading(false);
              }
            );
            // setLoading(false);
            //sessionStorage.setItem("address", JSON.stringify(res?.data?.data));
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrors({
            ...errors,
            pan_card_number: err?.response?.data?.errors?.pan_card_number,
            mobile_number: err?.response?.data?.errors?.mobile_number,
            aadhaar_card_number:
              err?.response?.data?.errors?.aadhaar_card_number,
          });
          console.log(err?.response?.data?.errors);
        });
    }, 500);
  };
  useEffect(() => {
    getMethod(`api/user-address/${addressDetails?.user_id}`).then((res) => {
      const data = res.data.data[0];
      console.log("data", data);
      const addresstypename = addressType.find(
        (i) => i.id === data.address_document_id
      );
      console.log("addname", addresstypename);
      setPersonalData((prev) => ({
        ...prev,
        address_id: data.id,
        user_id: data.user_id,
        door_no: data.address,
        pin_code: data.postal_code,
        country: data.country_id,
        city: data.city_id,
        state: data.state_id,
        district: data.district,
        cr_door_no: data.address,
        cr_pin_code: data.postal_code,
        cr_country: data.country_id_1,
        cr_city: data.city_id_1,
        cr_state: data.state_id_1,
        cr_district: data.district_1,
        address_proof_type: data.address_document_id,
        upload_address_proof: data.address_document,
      }));
    });
  }, []);
  console.log(personalData);
  return (
    <div className="investTab">
      <Container className=" rounded-0 mt-1">
        <h6 className="text-warning">Permanent Address</h6>
        <form onSubmit={onSubmit}>
          <Row>
            {permanentAddress.map((detail, index) => (
              <Col xs={12} sm={12} md={6} lg={4}>
                {!isEdit && detail.type === "file" ? (
                  <div className="d-flex flex-column">
                    <Form.Label className="form-label">
                      Address Proof
                      {detail.required && (
                        <span className="text-danger">*</span>
                      )}{" "}
                    </Form.Label>
                    <span>{detail.filevalue?.split("\\").pop()}</span>
                  </div>
                ) : (
                  <>
                    <FormComponent
                      titleInfo={detail?.titleInfo}
                      key={index}
                      name={detail?.name}
                      info={detail?.info}
                      formRef={(el) => (formRef.current[index] = el)}
                      formLabel={detail?.formLabel}
                      type={detail?.type}
                      placeholder={detail?.placeHolder}
                      options={detail?.option}
                      required={detail?.required}
                      regex={detail?.regex}
                      isUpperCase={detail?.isUpperCase}
                      onHandleChange={onHandleChange}
                      validations={detail?.error}
                      value={detail?.value}
                      disabled={detail?.readonly}
                      selected={detail?.value}
                    />
                    <span>{detail.filevalue?.split("\\").pop()}</span>
                  </>
                )}
              </Col>
            ))}
          </Row>
          <h6 className="text-warning">Correspondence Address</h6>
          {/* <small>
              <Form.Check
                className="mt-1 mb-3"
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setPersonalData({
                      ...personalData,
                      is_correspondance_address: 1,
                      cr_door_no: personalData.door_no,
                      cr_street: personalData.street,
                      cr_country: personalData.country,
                      cr_state: personalData.state,
                      cr_district: personalData.district,
                      cr_city: personalData.city,
                      cr_pin_code: personalData.pin_code,
                    });
                  } else if (e.target.checked === false) {
                    setPersonalData({
                      ...personalData,
                      is_correspondance_address: 0,
                      cr_door_no: "",
                      cr_street: "",
                      cr_country: "",
                      cr_state: "",
                      cr_district: "",
                      cr_city: "",
                      cr_pin_code: "",
                    });
                  }
                }}
                id="sel"
                name="is_sameAddress"
                label="Select, if correspondence address is same as permanent address"
              />
            </small> */}

          <Row>
            {correspondenceAddress.map((detail, index) => (
              <Col xs={12} sm={12} md={6} lg={4}>
                <FormComponent
                  titleInfo={detail?.titleInfo}
                  key={index}
                  name={detail?.name}
                  info={detail?.info}
                  formRef={(el) => (formRef.current[index] = el)}
                  formLabel={detail?.formLabel}
                  type={detail?.type}
                  placeholder={detail?.placeHolder}
                  options={detail?.option}
                  required={detail?.required}
                  regex={detail?.regex}
                  isUpperCase={detail?.isUpperCase}
                  onHandleChange={onHandleChange}
                  validations={detail?.error}
                  value={detail?.value}
                  selected={detail?.value}
                  disabled={detail?.readonly}
                  readonly={detail?.readonly}
                />
              </Col>
            ))}
          </Row>
          {/* <div
            className="my-3 d-flex justify-content-center"
            style={{ columnGap: "2rem" }}
          >
            <button
              type="button"
              onClick={() => setIsEdit(!isEdit)}
              style={{ backgroundColor: "#fc9200" }}
              className="user_profile_button" //rounded-0 commonBtn w-50
            >
              {isEdit ? (
                "Cancel"
              ) : (
                <>
                  <MdModeEditOutline
                    style={{
                      fontSize: "16px",
                      marginRight: "5px",
                      marginLeft: "4px",
                    }}
                  />
                  <span>Edit</span>{" "}
                </>
              )}
            </button>
            {isEdit && (
              <button
                type="submit"
                style={{ backgroundColor: "#fc9200" }}
                className="user_profile_button" //rounded-0 commonBtn w-50
              >
                Save Changes
              </button>
            )}
          </div> */}
        </form>
      </Container>
    </div>
  );
};

export default Address;
