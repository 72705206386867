import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Fade, LightSpeed, Slide } from "react-reveal";
import { Link } from "react-router-dom";
import { getMethod } from "../../API_SERVICES/ApiServices";
import { onNextPage } from "../Homepage/InvestRegisterSection/investRegisterSlice";

import "./Home.css";

const Home = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    const controller = new AbortController();
    getMethod(`api/user-address/${user?.id}`, controller.signal).then((res) => {
      console.log("user-address", res);
      sessionStorage.setItem("address", JSON.stringify(res?.data?.data[0]));
    });

    getMethod(`api/user-nominee/${user?.id}`, controller.signal).then((res) => {
      console.log("user-nominee", res);
      sessionStorage.setItem("nominee", JSON.stringify(res?.data?.data[0]));
      //dispatch(profitPaymentDetails(res.data.data));
    });
    getMethod(`api/user-bank-account/${user?.id}`, controller.signal).then(
      (res) => {
        console.log("user-bank-account", res);
        sessionStorage.setItem("bank", JSON.stringify(res?.data?.data[0]));
        //dispatch(profitPaymentDetails(res.data.data));
      }
    );
    getMethod(`api/user-investment/${user?.id}`, controller.signal).then(
      (res) => {
        console.log("user-investment", res);
        sessionStorage.setItem(
          "investment",
          JSON.stringify(res?.data?.data[0])
        );
      }
    );

    return () => {
      controller.abort();
    };
  }, []);

  const handleClick = (id) => {
    sessionStorage.setItem("profileTab", id);
  };

  return (
    <Fade duration={1000}>
      <div className="homeContainer pt-0">
        <Container className="text-center d-flex flex-column justify-content-center align-items-center pt-5">
          <Container className="mt-5">
            <Link to="/my-investment">
              <img
                role="button"
                className="homeButtons"
                alt="myinvestment"
                src={require("../../assets/images/mtInvestmentBtn.png")}
              />
            </Link>

            <Link to="/new-investment">
              <img
                onClick={() => dispatch(onNextPage("personalInformation"))}
                role="button"
                className="homeButtons"
                alt="invest"
                src={require("../../assets/images/investBtn.png")}
              />
            </Link>

            <Link to="/user/profile">
              <img
                onClick={() => handleClick("profile")}
                role="button"
                className="homeButtons"
                alt="profile"
                src={require("../../assets/images/myprofileBtn.png")}
              />
            </Link>
          </Container>
          <Container>
            <Link to="/user/profile">
              <img
                onClick={() => handleClick("nominee")}
                role="button"
                className="homeButtons"
                alt="nominee"
                src={require("../../assets/images/nomineeBtn.png")}
              />
            </Link>
            <Link to="/user/profile">
              <img
                onClick={() => handleClick("address")}
                role="button"
                className="homeButtons"
                alt="address"
                src={require("../../assets/images/addressBtn.png")}
              />
            </Link>
            <Link to="/downloads">
              <img
                role="button"
                className="homeButtons"
                alt="download"
                src={require("../../assets/images/downloadBtn.png")}
              />
            </Link>
          </Container>
        </Container>
      </div>
    </Fade>
  );
};

export default Home;
