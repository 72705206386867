import { color } from "@mui/system";
import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Fade } from "react-reveal";

const ContactUs = () => {
  const connectToUs = (e) => {
    e.preventDefault();
  };

  const redirectUrl = (url) => {
    window.open(url);
  };
  return (
    <Fade>
      <div className="contactUsContainer  p-5 ">
        <Container className="bg-light p-lg-5">
          <Row>
            <Col xs={12} sm={12} md={5}>
              <h1 style={{ color: "#fc9200" }} className=" fw-bold mt-2">
                Connect with us.
              </h1>
              <p>
                Thank you for visiting us. Please share your details and our
                team will connect with you to provide you with the best
                workspace solutions available for you.
              </p>
              <p className="fs-6">
                <i class="fas fa-phone-alt  faIcons"></i> +91 7559977150
              </p>
              <p>
                <i class="fas fa-envelope-open-text faIcons"></i>{" "}
                harism@mikrografeio.com
              </p>
              <p>
                <i class="fas fa-map-marker-alt faIcons"></i>
                <span className="fw-bold">Corporate Office</span> <br />
                <p style={{ marginLeft: "30px" }}>
                  #678, || 2nd floor, Classic House, 17th Main, 6th A Cross, 3rd
                  Block, Koramangala, Bangalore - 560034
                </p>
              </p>
              <p>
                <i class="fas fa-map-marker-alt faIcons"></i>
                <span className="fw-bold">Registered Office</span> <br />{" "}
                <p style={{ marginLeft: "30px" }}>
                  Sanhasa Square, No. 17, Bharathi Park 3rd Cross, Saibaba
                  Colony, Coimbatore - 641 043
                </p>
              </p>
            </Col>
            <Col style={{ marginLeft: "auto" }} xs={12} sm={12} md={6}>
              <div className="partner-field mt-md-2">
                <Form onSubmit={connectToUs} className="contact-form">
                  <Form.Control
                    className="rounded-0 border-light border-5 bg-transparent form-control text-white mb-3"
                    type="text"
                    placeholder="Name of the company"
                  />
                  <Form.Control
                    className="rounded-0 border-light border-5 bg-transparent form-control text-white mb-3"
                    type="text"
                    placeholder="Individual Name"
                  />
                  <Form.Control
                    className="rounded-0 border-light border-5 bg-transparent form-control text-white mb-3"
                    type="text"
                    placeholder="Email ID"
                  />

                  <Form.Control
                    className="rounded-0 border-light border-5 bg-transparent form-control text-white mb-3  "
                    type="text"
                    placeholder="Contact Number"
                  />

                  <Form.Control
                    className="rounded-0 border-light border-5 bg-transparent form-control text-white "
                    type="text"
                    placeholder="City"
                  />

                  <Form.Control
                    className="rounded-0  border-5  form-control submit_btn text-black fw-bold mt-3 mb-3"
                    type="SUBMIT"
                    placeholder="SUBMIT"
                  />
                </Form>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-between align-items-end">
            <Col className="py-4" xs={12} sm={12} md={6}>
              <h3 className="m-2">
                <i
                  role="button"
                  onClick={() =>
                    redirectUrl("https://www.facebook.com/BRHSocial")
                  }
                  className="fab fa-facebook-f faIcons"
                ></i>
                Facebook
              </h3>
              <h3 className="m-2">
                <i
                  role="button"
                  onClick={() =>
                    redirectUrl("https://www.instagram.com/brhsocial/")
                  }
                  className="fab fa-instagram faIcons"
                ></i>
                Instagram
              </h3>
              <h3 className="m-2">
                <i
                  role="button"
                  onClick={() =>
                    redirectUrl("https://www.linkedin.com/company/brhsocial/")
                  }
                  className="fab fa-linkedin-in faIcons"
                ></i>
                Linkedin
              </h3>
              <h3 className="m-2">
                <i
                  role="button"
                  onClick={() => redirectUrl("https://twitter.com/brhsocial")}
                  className="fab fa-twitter faIcons"
                ></i>
                Twitter
              </h3>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <iframe
                className="mapContainer"
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6510798873187!2d77.62107101473985!3d12.930133090883114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae152e5132eba5%3A0xd77dbb494b32623d!2sMikro%20Grafeio%20Services%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1673858124869!5m2!1sen!2sin"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    </Fade>
  );
};

export default ContactUs;
