import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import PersonalInformation from "./components/Homepage/InvestRegisterSection/PersonalInformation";
import YourAddress from "./components/Homepage/InvestRegisterSection/YourAddress";
import NomineeDetails from "./components/Homepage/InvestRegisterSection/NomineeDetails";
import InvestRegistrationComponent from "./components/Homepage/InvestRegisterSection/InvestRegistrationComponent";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import NavigationBar from "./components/NavigationSection/NavigationBar";
import PageNotFound from "./components/PageNotFound";
import Home from "./components/NavigationSection/Home";
import DownloadForms from "./components/NavigationSection/DownloadForms";
import LandingScreen from "./components/Homepage/LandingScreen";
import MyInvestments from "./components/NavigationSection/MyInvestments";
import MyInvestments1 from "./components/NavigationSection/MyInvestments1";
import Address from "./components/Homepage/User Section/Address";
import Nominee from "./components/Homepage/User Section/Nominee";
import Profile from "./components/Homepage/User Section/Profile";
import MyProfileComponent from "./components/Homepage/User Section/MyProfileComponent";
import ContactUs from "./components/NavigationSection/ContactUs";
import OurProjects from "./components/NavigationSection/OurProjects";
import Faqs from "./components/NavigationSection/Faqs";
import Footer from "./components/NavigationSection/Footer";

function App() {
  const user = JSON.parse(sessionStorage.getItem("user"));

  console.log(window.location.pathname);

  return (
    <Router>
      <NavigationBar />

      <Routes>
        <Route exact path="/invest" element={<InvestRegistrationComponent />} />
        <Route exact path="/faqs" element={<Faqs />} />
        <Route exact path="/our-projects" element={<OurProjects />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route
          exact
          path="/new-investment"
          element={
            user ? <InvestRegistrationComponent /> : <Navigate to="/" replace />
          }
        />
        <Route
          exact
          path="/home"
          element={user ? <Home /> : <Navigate to="/" replace />}
        />
        <Route
          exact
          path="/my-investment"
          element={user ? <MyInvestments1 /> : <Navigate to="/" replace />}
        />
        <Route
          exact
          path="/downloads"
          element={user ? <DownloadForms /> : <Navigate to="/" replace />}
        />

        <Route
          exact
          path="/user/profile"
          element={user ? <MyProfileComponent /> : <Navigate to="/" replace />}
        />
        <Route path="*" element={<PageNotFound />} />
        <Route
          exact
          path="/"
          element={!user ? <LandingScreen /> : <Navigate to="/home" replace />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
