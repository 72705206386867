import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getMethod } from "../../../API_SERVICES/ApiServices";
import "../../../App.css";

const SupplementaryAgreement = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const nominee = JSON.parse(sessionStorage.getItem("nominee"));
  const invested = JSON.parse(sessionStorage.getItem("investmentDetails"));
  const investment = JSON.parse(sessionStorage.getItem("investment"));
  const bank = JSON.parse(sessionStorage.getItem("bank"));
  const tranche = JSON.parse(sessionStorage.getItem("tranche"));
  const address = JSON.parse(sessionStorage.getItem("address"));
  const d = new Date();
  const date = d.getDate();
  const month = d.getMonth();
  console.log(typeof month);
  const year = d.getFullYear();
  const priceFormat = (price) => parseInt(price).toLocaleString("en-IN");
  const [investData, setInvestData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    getMethod(`api/user/${user?.id}`, controller.signal).then((res) => {
      if (res?.data?.status === true) {
        setInvestData(res?.data?.data?.user_investment);
        setLoading(false);
      }
    });

    return () => {
      controller.abort();
    };
  }, []);

  console.log("investData", investData?.length);

  return (
    <Container>
      {investData?.length === 0 && (
        <>
          <h5 className="text-center text-uppercase">
            Supplementary LLP Agreement
          </h5>
          <p>
            This Supplementary LLP Agreement is made and executed at Bangalore
            on this the day{" "}
            {`${date.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} / ${month.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} / ${year} `}
          </p>
          <h6 style={{ fontWeight: "bold" }}>BY</h6>
          <p>
            ( Mr/Mrs/Ms):
            <span className="fw-bold text-capitalize">
              {" " + user?.name + " "}
            </span>
            , (S/o or W/o or D/o):
            <span className="fw-bold">
              {" " + nominee?.nominee_name + " "}
            </span>{" "}
            , currently residing at
            <span className="fw-bold">{" " + address?.address + ", "}</span>
            PAN No :
            <span className="fw-bold">{" " + user?.pan_card_number + " "}</span>
            (hereinafter referred to as the “New Partner”, which expression
            shall, where the context admits, include his or heirs, executors and
            administrators);
          </p>
          <h6 style={{ fontWeight: "bold" }}>IN FAVOUR OF</h6>
          <p>
            All the existing partners of the LLP (hereinafter referred to as the
            “Existing Partners”, which expression shall, where the context
            admits, include each of their respective heirs, executors and
            administrators); and
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>BRH Infra LLP,</span>
            <span>{" PAN No:" + user?.pan_card_number + " "}</span>a limited
            liability partnership incorporated under the laws of India and
            having its registered office at 678, 2nd floor, Classic House, 17th
            Main, 6th A Cross Rd, 3rd Block, Koramangala, Bengaluru, Karnataka
            560034. (hereinafter referred to as the “LLP”, which expression
            shall, where the context admits, include its successors and
            permitted assigns).
          </p>
          <p style={{ fontWeight: "bold" }}>WHEREAS:</p>
          <p>
            i. The Existing Partners are parties to a limited liability
            partnership agreement dated thirteenth October Two Thousand
            Twenty-two (13/10/2022) (“LLP Agreement”), which sets out the terms
            and conditions pertaining to, inter alia, their inter se rights and
            obligations;
          </p>
          <p>
            ii. Pursuant to discussions between the Designated Partners and the
            New Partner, the Designated Partners have agreed to induct the New
            Partner as a partner of the LLP; and
          </p>
          <p>
            iii. The New Partner has agreed to be bound by the LLP Agreement and
            certain other terms set out herein by way of entering into this
            Deed.
          </p>
          <p>NOW THEREFORE THIS DEED WITNESSETH AS FOLLOWS:</p>
          <p>
            1. The New Partner shall bring in a contribution as stated in
            Schedule 1 to the LLP, in the manner as may be agreed between the
            Designated Partners and such New Partner and as required by the LLP.
            The contribution by the New Partner shall be utilized by the LLP for
            the purpose of conducting its general business, including but not
            limited to, paying deposits, carrying out interiors and developing
            infrastructure capabilities for the premises set out in Schedule 2
            (“Premises”) or for such other purpose as may be decided by the
            Designated Partners.{" "}
          </p>
          <p>
            2. The New Partner shall be entitled to receive an interest for the
            loan provided to the LLP as per the loan agreement mutually agreed.
            All payments shall be subject to tax deduction at source (“TDS”) at
            applicable rates and the same shall be deposited with the
            appropriate authority within the applicable due date. TDS
            certificate will be issued within the timelines prescribed under
            Income Tax Act, 1961 and rules thereunder.
          </p>
          <p>
            3. It is hereby agreed by the parties that, except for the amount
            payable under Clause 2, the New Partner shall not be entitled to any
            claim or interest, goodwill revenue/profits, remuneration, other
            assets in LLP at any point of time in the LLP.
          </p>
          <p>
            4. Upon repayment of loan and payback of capital contribution of the
            New Partner, the Designated Partners shall be entitled to release
            the New Partner from the LLP by providing a prior written notice of
            30 (thirty) days to the New Partner. The New Partner shall not be
            entitled to receive any additional benefits/rights / claim goodwill/
            any other assets at the time of such retirement, except as defined
            in Clause 2.
          </p>
          <p>
            5. The New Partner cannot be expelled by the other Existing
            Partners, except where the New Partner has been found guilty of:
            &nbsp; <br /> a. carrying on the business of the LLP with a
            fraudulent purpose; and/or &nbsp; <br /> b. acting in a way that is
            detrimental to the interest of the LLP or its business in any
            manner.
            <br />
            In such case, the New Partner may be removed as a partner of the LLP
            with the consent of the Designated Partners.
          </p>
          <p>
            6. In the event of death of the New Partner before the expiry of the
            Term, the LLP shall continue its business by admitting the nominee
            of the New Partner after obtaining the written consent of the said
            nominee. All terms of this Deed shall apply to the said nominee in
            the same manner as applicable to the New Partner. The details of the
            said nominee is as set out below:
          </p>
          <p>{" " + nominee?.nominee_name + ", " + nominee?.address}</p>
          <p>
            7. The New Partner shall be just and faithful to the LLP. The New
            Partner shall render to the Designated Partners true accounts and
            full information of all things affecting the LLP and shall attend to
            the beneficial working of the LLP.
          </p>
          <p>
            8. The New Partner shall at all times pay and discharge his separate
            and private debts and engagements whether present or future and keep
            the LLP and the Designated Partners indemnified therefrom and all
            other actions, proceedings, court claims and demands in respect
            thereof. It is hereby clarified that, the LLP shall not be
            responsible for the personal liabilities and/or loans of the New
            Partner. It is further clarified that, the New Partner shall not be
            responsible for the liabilities and/or loans of the LLP and the LLP,
            along with the Designated Partners, shall keep the concerned New
            Partner indemnified from actions, proceedings, court claims and
            demands in respect thereof.
          </p>
          <p>
            9. In no event shall any party be liable for any special, punitive,
            indirect, consequential or incidental damages arising out of or
            relating to this Deed even if previously advised of the possibility
            of such damages and regardless of whether such liability arises out
            of contract, negligence, tort, strict liability or any other theory
            of legal liability; and in no event shall the cumulative liability
            of any party hereunder exceed the capital contribution amount paid
            by the concerned New Partner.
          </p>
          <p>
            10. The New Partner shall not assign, mortgage or charge his share
            in the LLP without the prior written consent of the Designated
            Partners.
          </p>
          <p>
            11. Subject to what is otherwise provided herein, the New Partner
            hereby accedes to the terms of the LLP Agreement and undertakes to
            the Existing Partners and the LLP that he shall observe, perform and
            be bound by the LLP Agreement in all respects as if the New Partner
            were an original party to the LLP Agreement with respect to the
            terms, conditions, obligations, covenants, representations and
            warranties and were named therein as a party.
          </p>
          <p>
            12. The New Partner hereby agrees and acknowledges that he shall not
            be entitled to be appointed as a designated partner of the LLP nor
            be involved in the day- to-day operations of the LLP.
          </p>
          <p>
            13. The New Partner acknowledges that this Deed shall be appended to
            the LLP Agreement and form a part thereof, and, by reference,
            incorporate and be governed by the terms thereof.
          </p>
          <p>
            IN WITNESS WHEREOF, this Deed has been executed as a deed on the day
            and year first above written.
          </p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>
                  Signed and delivered by the within named Existing Partners
                  represented by Mr.Santosh Mahalingam
                </td>
                <td> _____________________</td>
              </tr>
              <tr>
                <td>
                  Signed and delivered by {" Mr/Mrs/Ms " + user?.name}, the “New
                  Partner” aforesaid.
                </td>
                <td> _____________________</td>
              </tr>
              <tr>
                <td>In the presence of (Witness Mr. Pavana Kumar)</td>
                <td> _____________________</td>
              </tr>
              <tr>
                <td>In the presence of (Witness Mrs.Sandhya)</td>
                <td> _____________________</td>
              </tr>
            </tbody>
          </table>
          <h6 className="text-center">Schedule 1</h6>
          <p className="text-center">Agreement Commercials</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>New Partner Name</td>
                <td>{user?.name}</td>
              </tr>
              <tr>
                <td>Effective Date</td>
                <td>{`${date.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${month.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${year} `}</td>
              </tr>
              <tr>
                <td>Investment Date</td>
                <td>{`${date.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${month.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })} / ${year} `}</td>
              </tr>
              <tr>
                <td>Capital Contribution</td>
                <td> Rs.100/-</td>
              </tr>
              <tr>
                <td>Loan Contribution</td>
                <td>
                  TOTAL Rs.{priceFormat(invested?.deposit_amount) + " Only "} as
                  per the Investment Date mentioned above.
                </td>
              </tr>
              <tr>
                <td>Payment Frequency</td>
                <td>
                  {invested?.interest_payment_id === 1
                    ? "Monthly"
                    : invested?.interest_payment_id === 2
                    ? "Quarterly"
                    : "Yearly"}
                </td>
              </tr>
              <tr>
                <td>Interest rate</td>
                <td> 12% - Simple interest balance method</td>
              </tr>
              <tr>
                <td>Loan Term</td>
                <td> {invested?.investment_period + " "}months</td>
              </tr>
            </tbody>
          </table>
          <h6 className="text-center">Schedule 2</h6>
        </>
      )}

      <h5 className="text-center">SUPPLEMENTARY LOAN AGREEMENT </h5>
      <p>
        1. The new partner agrees to grant loan of (Indian Rupees)
        {" " + priceFormat(invested?.deposit_amount) + " "} only (“Principal”)
        to the LLP. The LLP agrees to repay the principal amount to the New
        Partner, with return on Principal payable on the unpaid principal at the
        rate of 12% percent per annum commencing from{" "}
        {
          <span>{`${date.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })} / ${month.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })} / ${year} `}</span>
        }{" "}
        as detailed in Schedule I of this Agreement.{" "}
      </p>
      <p>
        2. The LLP shall have the right (not an obligation) to prepay the
        outstanding Loan (whether in part or whole) at any point of time after
        24 months from the commencement of the loan, during the term of
        repayment detailed in Schedule I, without any cost, penalty and/or
        charges of whatsoever nature.
      </p>
      <p>
        3. This Agreement shall become effective on execution. The Agreement
        shall stand terminated on the date the LLP has repaid the Loan amount in
        full along with applicable and due return on Principal.
      </p>
      <p>
        4. The New Partner provides the Loan amount to the LLP from his own
        funds. The Loan provide by new partner to the LLP is an unsecured loan.
      </p>
      <p>
        5. Notwithstanding anything to the contrary in this Agreement, if the
        LLP continuously defaults in the repayment of Loan amount for three
        months under this Agreement, then the new partner may declare the
        principal amount owing and return on Principal due under this Agreement
        at that time to be immediately due and payable provided such default in
        payment is not cured within fifteen days from the date of notification
        of such default in writing to the LLP.
      </p>
      <p>
        6. The new partner shall not assign, either directly or indirectly, this
        Agreement to any third party.
      </p>
      <p>
        7. All disputes, differences and/or claims, arising out of this
        Agreement, whether during its subsistence or thereafter, shall be
        settled by arbitration in accordance with the provisions of the
        Arbitration and Conciliation Act, 1996 as may be in force from time to
        time. The sole arbitration will be mutually appointed by both Parties.
        The seat and venue of arbitration shall be at Chennai.
      </p>
      <p>
        8. Subject to the Arbitration Clause mentioned above, this Agreement
        shall be governed and construed in accordance with the substantive laws
        of India and the parties here to submit to the exclusive jurisdiction of
        the Courts at Chennai.
      </p>
      <p>
        9. IN WITNESS WHEREOF, the parties have signed under hand and seal on
        this 06th June 2022.
      </p>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>
              Signed and delivered by the within named Existing Partners
              represented by (Mr. Santosh Mahalingam)
            </td>
            <td>____________</td>
          </tr>
          <tr>
            <td>
              Signed and delivered by {user?.name}., the “New Partner”
              aforesaid.
            </td>
            <td>____________</td>
          </tr>
          <tr>
            <td>In the presence of (Witness Mr. Pavana Kumar)</td>
            <td>____________</td>
          </tr>
          <tr>
            <td>In the presence of (Witness Mrs.Sandhya)</td>
            <td>____________</td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

export default SupplementaryAgreement;
