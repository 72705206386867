import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
} from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import OTPInput, { ResendOTP } from "otp-input-react";
import DatePicker from "react-datepicker";

import {
  onInvestmentDetails,
  onPersonalDetails,
} from "../Homepage/InvestRegisterSection/investRegisterSlice";
import { getData, getMethod, postMethod } from "../../API_SERVICES/ApiServices";

import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useSelector } from "react-redux";
import {
  countryDetails,
  investmentPeriodDetails,
  profitPaymentDetails,
} from "./LandingPageSlice";
import moment from "moment/moment";

import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { Fade, Flip, Bounce, Roll } from "react-reveal";
import toast from "react-simple-toasts";

const MySwal = withReactContent(Swal);
const priceFormat = (str) => parseInt(str).toLocaleString("en-IN");

const LandingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState({
    prospect_name: null,
    prospect_email: null,
    prospect_phone: null,
    slot_date: "",
    slot_time: "",
  });
  const [loading, setLoading] = useState(false);
  const [btnName, setBtnName] = useState("Proceed to Login.");
  const [schedule, setSchedule] = useState(true);
  const [scheduleForm, setScheduleForm] = useState(false);

  const [scheduleDetails, setScheduleDetails] = useState({
    prospect_name: null,
    prospect_email: null,
    prospect_phone: null,
    slot_date: null,
    slot_time: null,
  });

  const landingScreenStates = useSelector(
    (state) => state?.landingScreenStates
  );

  //console.log("landingScreenStates", landingScreenStates);

  const investmentPeriod = landingScreenStates?.investmentPeriod;
  const profitPayment = landingScreenStates?.profitPaymentMode;

  useEffect(() => {
    //console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    getMethod("api/investment-period", controller.signal).then((res) => {
      dispatch(
        investmentPeriodDetails(res.data.data?.filter((i) => i.status !== 0))
      );
    });

    getMethod("api/interest-profit-payment", controller.signal).then((res) => {
      dispatch(
        profitPaymentDetails(res.data.data?.filter((i) => i.status !== 0))
      );
    });

    return () => {
      controller.abort();
    };
  }, []);

  const [depositAmount, setDepositAmount] = useState(0);
  const [profitAmount, setProfitAmount] = useState("");
  const [periodCalcAmount, setPeriodCalcAmount] = useState("");
  const [periodAmount, setPeriodAmount] = useState("");
  const [profitMode, setProfitMode] = useState("");
  const [investmentPeriodID, setInvestmentPeriodID] = useState("");
  const [intrestPaymentID, setInterestPaymentID] = useState("");
  const [minValAlert, setMinValAlert] = useState(false);
  const [emptyValAlert, setEmptyValAlert] = useState(false);
  const [emptyPeriodAmount, setEmptyPeriodAmount] = useState("");

  useEffect(() => {
    //console.log("periodCalcAmount", periodCalcAmount);
  }, [periodCalcAmount]);
  useEffect(() => {
    //console.log("profitAmount", profitAmount, profitMode);
  }, [profitAmount, profitMode]);

  const val = depositAmount * periodAmount;
  const finPeriod = (val / 12) * 0.12;
  const finPlan = (finPeriod / periodAmount) * profitMode;
  //console.log("5754", finPlan);
  const onHandleChange = (e) => {
    let eVal = Number(e.target.value);
    setInterestPaymentID(eVal);
    let num;
    if (eVal === 1) {
      setProfitMode(1);
      num = 1;
    } else if (eVal === 2) {
      setProfitMode(3);
      num = 3;
    } else if (eVal === 3) {
      setProfitMode(12);
      num = 12;
    }
    let finVal = (periodCalcAmount / periodAmount) * num;
    setProfitAmount(finVal);
  };
  const onHandleDeposit = (e) => {
    let myValue;

    let eVal = e;

    if (e === "") {
      setDepositAmount("");
    } else {
      const newValue = e;

      setDepositAmount(newValue);
      myValue = newValue;
    }

    if (Number(myValue) < 1000000) {
      setMinValAlert(true);
    } else {
      setMinValAlert(false);
    }

    if (myValue == 0) {
      setEmptyValAlert(true);
    } else {
      setEmptyValAlert(false);
    }

    if (periodAmount != "") {
      let val = eVal * periodAmount;
      let fin = (val / 12) * 0.12;
      setPeriodCalcAmount(fin);
    } else if (periodCalcAmount != "") {
      let finVal = periodCalcAmount / periodAmount;
      setProfitAmount(finVal);
    }
  };

  const onHandlePeriodChange = (e) => {
    let eVal = Number(e.target.value);
    console.log("PeriodChange", eVal);
    setPeriodAmount(eVal);
    setInvestmentPeriodID(
      eVal === 24 ? 1 : eVal === 36 ? 2 : eVal === 48 ? 3 : 4
    );
    if (depositAmount != "" && depositAmount >= 500000) {
      let val = depositAmount * eVal;
      let fin = (val / 12) * 0.12;
      setPeriodCalcAmount(fin);
    } else if (depositAmount == "") {
      setEmptyValAlert(true);
    } else {
      setEmptyValAlert(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (emptyValAlert) {
      return;
    } else if (minValAlert) {
      return;
    } else {
      let data = {};
      data.deposit_amount = depositAmount;
      data.interest_payment = profitMode;
      data.investment_period = periodAmount;
      data.profit_amount = profitAmount;
      data.maturity_amount = profitAmount;
      data.resident = 1;
      data.tranche = null;
      data.investment_period_id = investmentPeriodID;
      data.interest_payment_id = intrestPaymentID;

      dispatch(onInvestmentDetails(data));
      sessionStorage.setItem("investmentDetails", JSON.stringify(data));
      navigate("/invest");
    }
  };

  const [loginDetails, setLoginDetails] = useState(null);
  const [loginDetailsError, setLoginDetailsError] = useState(null);
  const [otpSent, setOtpSent] = useState(null);
  const [otpErr, setOtpErr] = useState(null);
  const [checkedDetails, setCheckedDetails] = useState(false);
  const [OTP, setOTP] = useState("");
  const [userId, setUserId] = useState(null);

  const requestForOTP = () => {
    setLoading(true);
    postMethod("api/user-authenticate", {
      pan_card_number: loginDetails,
    })
      .then((res) => {
        if (res?.data?.status === true) {
          //console.log(res);
          setCheckedDetails(true);

          setLoginDetailsError(null);
          setTimeout(() => {
            setOtpSent(null);
          }, 5000);
          setOtpSent(res?.data?.message);
          setLoading(false);
          setUserId(res?.data?.data?.id);
        }
      })
      .catch((err) => {
        //console.log(err?.response?.data?.message);
        setLoginDetailsError(err?.response?.data?.message);
      });
  };

  const proceedToLogin = () => {
    setLoading(true);
    postMethod("api/user-otp-verification", {
      user_id: userId,
      otp: OTP,
    })
      .then((res) => {
        if (res?.data?.status === true) {
          setLoading(false);
          setBtnName("Login Done Successfully");
          dispatch(onPersonalDetails(res?.data?.data));
          setOtpSent(res?.data?.message);
          sessionStorage.setItem("user", JSON.stringify(res?.data?.data));
          //console.log(res);
          //    setTimeout(() => navigate("/home"), 3000  );
          window.location.href = "/home";
        }
      })
      .catch((err) => {
        //console.log(err?.response?.data?.message);
        setOtpErr(err?.response?.data?.message);
      });
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const scheduleCall = () => {
    setSchedule(false);
    setScheduleForm(true);
  };

  const onCancel = () => {
    setSchedule(true);
    setScheduleForm(false);
  };

  const submitInfo = (e) => {
    e.preventDefault();
    if (scheduleDetails?.prospect_name === null) {
      return setValidated({
        ...validated,
        prospect_name: "Name is mandatory",
      });
    } else if (scheduleDetails?.prospect_email === null) {
      return setValidated({
        ...validated,
        prospect_email: "Email is mandatory",
      });
    } else if (scheduleDetails?.prospect_phone === null) {
      return setValidated({
        ...validated,
        prospect_phone: "Mobile Number is mandatory",
      });
    } else if (scheduleDetails?.slot_date === null) {
      return setValidated({
        ...validated,
        slot_date: "Date is mandatory",
      });
    } else if (scheduleDetails?.slot_time === null || time === "") {
      return setValidated({
        ...validated,
        slot_time: "Time is mandatory",
      });
    } else {
      postMethod("api/user-enquiries", scheduleDetails)
        .then((res) => {
          if (res?.data?.status === true) {
            toast(res?.data?.message, 6000);
            setScheduleDetails({
              prospect_name: "",
              prospect_email: "",
              prospect_phone: "",
              slot_date: "",
              slot_time: "",
            });
          }
        })
        .catch((err) => err?.response?.data?.message);
    }
  };

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const [time, setTime] = useState(""); // State for selected time

  const generateTimeOptions = () => {
    const interval = 15; // interval in minutes
    const startTime = 9 * 60; // start time in minutes (9:00 AM)
    const endTime = 21 * 60; // end time in minutes (9:00 PM)

    const timeOptions = [];

    let currentTime = startTime;
    while (currentTime <= endTime) {
      const hours = Math.floor(currentTime / 60);
      const minutes = currentTime % 60;

      const period = hours < 12 ? "AM" : "PM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = ("0" + minutes).slice(-2);

      const timeOption = `${formattedHours}:${formattedMinutes} ${period}`;
      timeOptions.push(timeOption);

      currentTime += interval;
    }

    return timeOptions;
  };

  useEffect(() => {
    // Set current time if it's within the range of 9:00 AM to 9:00 PM
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    if (currentTimeInMinutes >= 9 * 60 && currentTimeInMinutes <= 21 * 60) {
      const roundedMinute = Math.ceil(currentMinute / 15) * 15; // Round up to the nearest multiple of 15
      const period = currentHour < 12 ? "AM" : "PM";
      const currentFormattedTime = `${currentHour % 12 || 12}:${(
        "0" + roundedMinute
      ).slice(-2)} ${period}`;
      setTime(currentFormattedTime);
      setScheduleDetails({
        ...scheduleDetails,
        slot_time: `${("0" + currentHour).slice(-2)}:${(
          "0" + currentMinute
        ).slice(-2)}`,
      });
    }
  }, []);

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    setTime(selectedTime);

    // Convert selected time to 24-hour format and update the state
    const [hours, minutes, period] = selectedTime.split(/:|\s/);
    let selectedHour = parseInt(hours, 10);
    let selectedMinute = parseInt(minutes, 10);

    if (period === "PM" && selectedHour < 12) {
      selectedHour += 12;
    }
    if (period === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }

    setScheduleDetails({
      ...scheduleDetails,
      slot_time: `${("0" + selectedHour).slice(-2)}:${(
        "0" + selectedMinute
      ).slice(-2)}`,
    });
  };

  return (
    <>
      <div className="home_bg_container">
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-5 col-lg-5 tablet_padding_view_form">
              <div className="card mainCardBorder rounded-0 mt-3">
                <div className="row my-3 align-items-center ">
                  <div className="col-7 bgorange text-dark fw-bold py-2">
                    ROI Calculator
                  </div>
                  <div className="col-5 text-end pe-4">
                    <OverlayTrigger
                      trigger="hover"
                      placement="auto"
                      overlay={
                        <Popover id={`popover-posi`}>
                          <Popover.Body>
                            <small className="fw-bold">
                              Keep these documents ready before your start.
                            </small>
                            <ol>
                              <li>Photo</li>
                              <li>PAN Card</li>
                              <li>Aadhaar Card</li>
                              <li>Cancelled Bank Cheque</li>
                            </ol>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div role="button">
                        <small>Documents</small>

                        <img
                          onClick={handleClick}
                          width={14}
                          alt="doc"
                          src={require("../../assets/images/8.png")}
                          style={{ marginLeft: " 8px" }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="row mx-3">
                    <div className="col">
                      <label>Enter Investment Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        autoComplete="off"
                        // onKeyPress={(event) => {
                        //   if (!/[0-9]/.test(event.key)) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        min={1000000}
                        maxLength={15}
                        required
                        value={depositAmount.toLocaleString("en-IN")}
                        onChange={(event) =>
                          onHandleDeposit(event.target.value)
                        }
                      />
                      {!minValAlert && (
                        <small>
                          Minimum deposit for the first Investment is Rs.
                          10,00,000/-
                        </small>
                      )}
                      {minValAlert && (
                        <div className="text-danger">
                          Minimum Investment is Rs. 10,00,000/-
                        </div>
                      )}
                      {emptyValAlert && (
                        <div className="text-danger">
                          Please Enter Deposit Amount.
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className="mx-4"></hr>
                  <div className="row mx-3">
                    <div className="col">
                      <label>Select Investment period (in months)</label>
                      <div
                        className="mt-2 d-flex align-items-center"
                        style={{ justifyContent: "space-around" }}
                      >
                        {investmentPeriod?.map((period, index) => (
                          <div className="inputGroup mx-2">
                            <input
                              id={index}
                              name="option"
                              type="radio"
                              onChange={(event) => onHandlePeriodChange(event)}
                              value={period?.investment_period}
                              required
                              onInvalid={() =>
                                setEmptyPeriodAmount("Select Investment Period")
                              }
                            />
                            <label htmlFor={index}>
                              {period?.investment_period}
                            </label>
                          </div>
                        ))}
                      </div>
                      {emptyPeriodAmount && (
                        <small className="text-danger">
                          {emptyPeriodAmount}
                        </small>
                      )}
                    </div>
                  </div>
                  <hr className="mx-4"></hr>

                  <div className="row mx-3 align-items-center">
                    <div className="col-md-4 col-xs-6 col-sm-6">
                      Profit Rate
                    </div>
                    <div className="col-md-4 col-xs-6 col-sm-6 orange">
                      <h3 className="d-inline fw-bold">12%</h3>
                      <h6 className="d-inline"> p.a</h6>
                    </div>
                    <div className="col-md-4 col-xs-12 col-sm-12">
                      <h6>Total Profit Amount</h6>
                      <div className="orange">
                        ₹&nbsp;{finPeriod ? priceFormat(finPeriod) : 0}
                      </div>
                    </div>
                  </div>
                  <hr className="mx-4"></hr>
                  <div className="row mx-3">
                    <label className="mb-3">Profit Payment</label>

                    <div className="row">
                      <Form.Group
                        className="d-flex  formCheckLabel"
                        style={{ justifyContent: "space-between" }}
                      >
                        {profitPayment?.map((profit, index) => (
                          <Form.Check
                            className="mx-1"
                            required
                            onChange={onHandleChange}
                            type="radio"
                            label={profit?.interest_profit_payment}
                            name="interest_payment"
                            value={profit?.id}
                          />
                        ))}
                      </Form.Group>
                    </div>
                  </div>
                  <hr className="mx-4"></hr>
                  <div className="row mx-3">
                    <div className="col text-center">
                      {profitMode === 1
                        ? "Monthly"
                        : profitMode === 3
                        ? "Quartely"
                        : profitMode === 12
                        ? "Annually"
                        : ""}
                      &nbsp; Profit Amount &nbsp;
                      <span className="orange">
                        ₹&nbsp;{finPlan ? priceFormat(finPlan) : 0}
                      </span>
                    </div>
                  </div>
                  <hr className="mx-4"></hr>
                  <div className="row mb-3 mx-2">
                    <div className="col p-0">
                      <button
                        type="submit"
                        className="btn bgorange text-white fw-bold w-100 signupBtn"
                      >
                        INVEST / REGISTER NOW
                        <i className="mdi mdi-chevron-right lead fw-bold"></i>
                      </button>
                    </div>
                    {schedule === true && (
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <h6 className="fw-bold">
                          Would you like to talk to an advisor?
                        </h6>
                        <Link className="orange" onClick={() => scheduleCall()}>
                          Schedule Call
                        </Link>
                      </div>
                    )}
                    {scheduleForm === true && (
                      <form>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-sm-12 ">
                            <h6 className="fw-bold my-2">
                              Would you like to talk to an advisor?
                            </h6>
                            <hr />
                            <label>
                              Enter Name <span className="text-danger">*</span>
                            </label>
                            <input
                              onChange={(e) =>
                                setScheduleDetails({
                                  ...scheduleDetails,
                                  prospect_name: e.target.value,
                                })
                              }
                              value={scheduleDetails?.prospect_name}
                              type="text"
                              name="prospect_name"
                              className="form-control rounded-0"
                              autoComplete="off"
                              required
                              onFocus={(e) => {
                                setValidated({
                                  ...validated,
                                  prospect_name: null,
                                });
                              }}
                            />
                            <small className="text-danger">
                              {validated?.prospect_name}
                            </small>
                          </div>
                          <div className="col-md-6 col-sm-12 col-sm-12 my-2">
                            <label>
                              Enter Email <span className="text-danger">*</span>
                            </label>
                            <input
                              value={scheduleDetails?.prospect_email}
                              onChange={(e) =>
                                setScheduleDetails({
                                  ...scheduleDetails,
                                  prospect_email: e.target.value,
                                })
                              }
                              type="email"
                              name="prospect_email"
                              className="form-control rounded-0"
                              autoComplete="off"
                              required
                              onFocus={(e) => {
                                setValidated({
                                  ...validated,
                                  prospect_email: null,
                                });
                              }}
                            />
                            <small className="text-danger">
                              {validated?.prospect_email}
                            </small>
                          </div>
                          <div className="col-md-6 col-sm-12 col-sm-12 my-2">
                            <label>
                              Enter Phone Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              value={scheduleDetails?.prospect_phone}
                              onChange={(e) =>
                                setScheduleDetails({
                                  ...scheduleDetails,
                                  prospect_phone: e.target.value,
                                })
                              }
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              name="prospect_phone"
                              className="form-control rounded-0"
                              autoComplete="off"
                              maxLength="10"
                              minLength="10"
                              required
                              onFocus={(e) => {
                                setValidated({
                                  ...validated,
                                  prospect_phone: null,
                                });
                              }}
                            />
                            <small className="text-danger">
                              {validated?.prospect_phone}
                            </small>
                          </div>
                          <div className="col-md-6 col-sm-12 h-auto col-sm-12 my-2">
                            <label>
                              Date <span className="text-danger">*</span>
                            </label>

                            <input
                              style={{
                                height: "3.7rem",
                              }}
                              className="w-100 form-control rounded-0 p-2"
                              onChange={(e) =>
                                setScheduleDetails({
                                  ...scheduleDetails,
                                  slot_date: e.target.value,
                                })
                              }
                              type="date"
                              name="slot_date"
                              value={scheduleDetails?.slot_date}
                              title="Select Available Time"
                              required
                              onFocus={(e) => {
                                setValidated({
                                  ...validated,
                                  slot_date: null,
                                });
                              }}
                              min={currentDate.toISOString().slice(0, -14)}
                            />
                            <small className="text-danger">
                              {validated?.slot_date}
                            </small>
                          </div>

                          <div className="col-md-6 col-sm-12 col-sm-12 my-2">
                            <label htmlFor="timeInput">
                              Time <span className="text-danger"> *</span>
                            </label>
                            <select
                              style={{
                                height: "3.7rem",
                              }}
                              id="timeInput"
                              name="time"
                              value={time}
                              className="w-100 form-select rounded-0"
                              onChange={handleTimeChange}
                              required
                              onFocus={(e) => {
                                setValidated({
                                  ...validated,
                                  slot_time: null,
                                });
                              }}
                            >
                              <option value="">Select Time</option>
                              {generateTimeOptions().map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <small className="text-danger">
                              {validated?.slot_time}
                            </small>
                          </div>

                          <div className="col-md-6 col-sm-12 col-sm-12 my-2"></div>
                          <div className="col-md-6 col-sm-12 col-sm-12 my-2 text-end">
                            <Button
                              type="button"
                              className=""
                              variant="outline-dark rounded-0"
                              onClick={(e) => onCancel(e)}
                            >
                              Cancel
                            </Button>
                            <button
                              className="mx-1 text-white fw-bold btn btnBtn  rounded-0"
                              type="button"
                              onClick={(e) => submitInfo(e)}
                            >
                              Schedule Call
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-1 col-lg-1"></div>
            <div className="col-md-6 col-lg-5" style={{ alignSelf: "end" }}>
              <div className="home_page_right_upper_box">
                <div className="right_upper_box_top">
                  <h3 className="home_investor_logIn_head">Investor Login</h3>
                  <p className="home_investor_logIn_para">
                    Enter below details to proceed
                  </p>
                </div>
                <div className="right_upper_box_bottom">
                  <p className="home_investor_logIn_para">
                    Enter PAN / TAN Number
                  </p>
                  <input
                    style={{ textTransform: "uppercase" }}
                    type="text "
                    onChange={(e) =>
                      setLoginDetails(e.target.value.toUpperCase())
                    }
                    className="form-control  mlAuto rounded-0 home_right_input"
                  ></input>

                  {loginDetailsError !== null && (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginLeft: "10px",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                      className="text-danger"
                    >
                      {loginDetailsError}
                    </span>
                  )}
                  {otpSent !== null && (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginLeft: "10px",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                      className="text-success"
                    >
                      {otpSent}
                    </span>
                  )}

                  {checkedDetails && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "60px",
                        marginTop: "5px",
                      }}
                    >
                      <OTPInput
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        //secure
                      />
                      <ResendOTP maxTime={120} onResendClick={proceedToLogin} />
                    </div>
                  )}

                  {otpErr !== null && (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginLeft: "10px",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                      className="text-danger"
                    >
                      {otpErr}
                    </span>
                  )}

                  <div className="mt-3">
                    {checkedDetails ? (
                      <button
                        type="button"
                        onClick={() => proceedToLogin()}
                        className="btn btn-dark w-75 rounded-0 logBtn align-items-center justify-content-center"
                      >
                        {btnName}
                        {loading && (
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="light"
                          />
                        )}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => requestForOTP()}
                        className="btn btn-dark  rounded-0 logBtn home_request_button"
                      >
                        Request for OTP to Registered Email
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="text_container">
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-6">
                    <h1 className="landingHeading">
                      <p className="orange"> JOIN THE</p>
                      <p className="white_color">WORKSPACE</p>
                      <p className="orange"> REVOLUTION</p>
                    </h1>
                    <h6 className="quoted_text">
                      AN INVESTMENT OPPORTUNITY <br /> THAT IS SAFE AND
                      INTERESTING
                    </h6>
                  </div>
                  <div className="col-12 col-md-4 col-lg-5">
                    <Roll>
                      <img
                        alt="badge"
                        src={require("../../assets/projects/badge.png")}
                        className="img_fluid_home_ad"
                      />
                    </Roll>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      {/* ------------------------ LAKSHMANAN --------------------------- */}

      <Fade big cascade>
        <div className="text_container_bottom">
          <div className="">
            <div className="container_revolution">
              <div className="container">
                <h2 className="heading_main">THE WORKSPACE REVOLUTION</h2>
                <div className="row section_revolution justify-content-between">
                  <div
                    className="col-12 col-md-5"
                    style={{ alignSelf: "center" }}
                  >
                    <p className="revolution_para_head">
                      There is a workspace revolution taking place globally, and
                      this is something that can’t be overlooked!
                    </p>
                    <p
                      style={{ letterSpacing: "2px", lineHeight: "30px" }}
                      className="revolution_para "
                    >
                      Organizations are continuously evolving their work-place
                      strategies to embrace the requirements of the workforce
                      through timely and regular employee engagement and win the
                      war for talent. This is possible only through workspace
                      environments that engage and provide quality amenities in
                      neighborhood locations that includes Tier 2 and 3 cities.
                    </p>
                  </div>
                  <div
                    className="col-12 col-md-6 "
                    style={{ alignSelf: "center" }}
                  >
                    <img
                      className="img-fluid"
                      alt="badge"
                      src={require("../../assets/projects/bg4.jpg")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="workspaceContainer">
            <div className="p-5">
              <h4 className="heading_main">
                THE WORKSPACE REVOLUTION
              </h4>

              <Row className="justify-content-center align-items-center">
                <Col sm={12} md={6} lg={6} xl={6}>
                  <p className="revolution_para_head">
                    There is a workspace revolution taking place globally, and
                    this is something that can’t be overlooked!
                  </p>
                  <p className="revolution_para">
                    Organisations are continuously evolving their work-place
                    strategies to embrace the requirements of the workforce
                    through timely and regular employee engagement and win the war
                    for talent. This is possible only through workspace
                    environments that engage and provide quality amenities in
                    neighbourhood locations that includes Tier 2 and 3 cities.
                  </p>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                  <img
                    width={300}
                    className="workspaceImage"
                    alt="badge"
                    src={require("../../assets/projects/bg4.jpg")}
                  />
                </Col>
              </Row>
            </div>
          </div> */}
      </Fade>
      <Fade big cascade>
        <div className="container_about">
          <div className="container">
            <h1 className="about_heading_main">
              ABOUT BRH INFRA & MIKRO GRAFEIO
            </h1>
            <div className="row section_about">
              <div className="col-12 col-md-6" style={{ alignSelf: "center" }}>
                <img
                  className="img-fluid"
                  alt="badge"
                  src={require("../../assets/projects/aboutMikro.jpg")}
                />
              </div>
              <div
                className="col-12 col-md-5 "
                style={{ alignSelf: "center", marginLeft: "auto" }}
              >
                <p className="about_para_head">
                  Mikro Grafeio is at the cusp of revolutionising the workspace
                  readiness business by investing in office spaces across India.
                  They are a social impact start-up and provide distributed
                  workspaces in Tier 2 & 3 cities and towns. Their customers are
                  a diverse mix from various industries that include Education,
                  IT, Retail, Travel and Real Estate to name a few! BRH Infra is
                  a Mikro Grafeio Group company which builds the infrastructure
                  for meeting their growth plans.
                </p>
                <i className="about_para">
                  "Organizations have to shift their mentality from creating a
                  place where they assume people NEED to work, to creating an
                  environment where people WANT to work",
                </i>
                <p className="about_para mt-1">
                  -
                  <span style={{ fontWeight: "bold", color: "#ffa94d" }}>
                    Jacob Morgan &nbsp;
                  </span>
                  The Future of Work.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="aboutContainer">
          <div className="p-5">
            <h4 className="fw-bold fs-2 orange text-center pb-3">
              ABOUT BRH INFRA & MIKRO GRAFEIO
            </h4>
            <Row className="justify-content-between align-items-center">
              <Col sm={12} md={6} lg={6} xl={6}>
                <img
                  width={300}
                  className="workspaceImage"
                  alt="badge"
                  src={require("../../assets/projects/aboutMikro.jpg")}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <h6 className=" fs-5 orange pt-4">
                  Mikro Grafeio is at the cusp of revolutionising the workspace
                  readiness business by investing in office spaces across India.
                  They are a social impact start-up and provide distributed
                  workspaces in Tier 2 & 3 cities and towns. Their customers are
                  a diverse mix from various industries that include Education,
                  IT, Retail, Travel and Real Estate to name a few! BRH Infra is
                  a Mikro Grafeio Group company which builds the infrastructure
                  for meeting their growth plans.
                </h6>
                <h6 className="pt-5">
                  <i className="fs-5 text-white ">
                    “Organizations have to shift their mentality from creating a
                    place where they assume people NEED to work, to creating an
                    environment where people WANT to work” Jacob Morgan, The
                    Future of Work.
                  </i>
                </h6>
              </Col>
            </Row>
          </div>
        </div> */}
      </Fade>
      <Fade big cascade>
        <div className="container_map">
          <div className="container">
            <h1 className="map_heading_main">
              MIKRO GRAFEIO WORKSPACE NETWORK
            </h1>
            <div className="section_map justify-content-center align-items-center text-center">
              <Row className="justify-content-center align-items-center">
                <Col sm={12} md={6} lg={6} xl={6}>
                  <Row className="justify-content-center align-items-center text-center p-4 map_imp_text_gap">
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <h1 className="map_imp_text_upper">21</h1>
                      <h5 className="map_imp_text_lower">CITIES & TOWNS</h5>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <h1 className="map_imp_text_upper">33</h1>
                      <h5 className="map_imp_text_lower">CENTERS</h5>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <h1 className="map_imp_text_upper">1000+</h1>
                      <h5 className="map_imp_text_lower">JOBS CREATED</h5>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <h1 className="map_imp_text_upper">125K</h1>
                      <h5 className="map_imp_text_lower">SQ.FT.</h5>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center text-center mt-3 ">
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <h4 className="map_location_head map_location_desktop_gap">
                        upcoming locations
                      </h4>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <h6 className="map_location_para">
                      ERODE <span className="map_syllabify">|</span> HOSUR{" "}
                      <span className="map_syllabify">|</span> VELLORE
                    </h6>
                    <h6 className="map_location_para">
                      HUBLI <span className="map_syllabify">|</span> BELGAUM{" "}
                      <span className="map_syllabify">|</span> THRISSUR
                    </h6>
                    <h6 className="map_location_para">
                      MANGALUR <span className="map_syllabify">|</span>{" "}
                      PUDUCHERRY <span className="map_syllabify">|</span>{" "}
                      HYDERABAD
                    </h6>
                    <h6 className="map_location_para">
                      VISHAKHAPATNAM <span className="map_syllabify">|</span>{" "}
                      VIJAYAWADA <span className="map_syllabify">|</span>{" "}
                      TIRUPATI
                    </h6>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6} className="d-none d-md-block">
                  <img
                    className="img-fluid"
                    alt="badge"
                    src={require("../../assets/projects/map.png")}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* <div className="mapSectionContainer">
          <div className="p-5">
            <h4 className="fw-bold fs-2 orange text-center mt-5">
              MIKRO GRAFEIO WORKSPACE NETWORK
            </h4>
            <Row className="justify-content-center align-items-center">
              <Col sm={12} md={6} lg={6} xl={6}>
                <Row className="justify-content-center align-items-center text-center p-4">
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <h1 className="orange fw-bold  mapFont">21</h1>
                    <h5>CITIES & TOWNS</h5>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <h1 className="orange fw-bold  mapFont">32</h1>
                    <h5>CENTERS</h5>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <h1 className="orange fw-bold  mapFont">1000+</h1>
                    <h5>JOBS CREATED</h5>
                  </Col>
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <h1 className="orange fw-bold  mapFont">125K</h1>
                    <h5>SQ.FT.</h5>
                  </Col>
                </Row>
                <Row className="justify-content-center align-items-center text-center mt-3 ">
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <h3 className="fw-bold mt-3">UPCOMING LOCATION</h3>
                  </Col>
                </Row>
                <div className="text-center">
                  <h6 className="fs-5">HUBLI |BELGIUM | THRISSUR </h6>
                  <h6 className="fs-5">
                    VISHAKHAPATNAM |MANGALUR | PUDUCHERRY{" "}
                  </h6>
                  <h6 className="fs-5">
                    VIJAYAWADA |ERODE | HOSUR | TIRUPATI | VELLORE | HYDERABAD{" "}
                  </h6>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <img
                  width={300}
                  className="workspaceImage"
                  alt="badge"
                  src={require("../../assets/projects/map.png")}
                />
              </Col>
            </Row>
          </div>
        </div> */}
      </Fade>
      <Fade big cascade>
        <div className="container_money">
          <div className="container">
            <h1 className="money_heading_main">HOW DOES IT WORK?</h1>
            <h5 className="money_subHeading text-center">
              YOUR INVESTMENT WILL FINANCE THE CAPEX FOR OFFICE INTERIOR WORKS
              OF REPUTED CLIENTS OF MIKRO GRAFEIO.
            </h5>
            <div className="row section_money">
              <div className="col-12 col-md-5" style={{ alignSelf: "center" }}>
                <p className="money_para_head">THE MONEY TRAIL</p>
                <p className="money_para">
                  Mikro Grafeio has huge requirement of workspaces in Tier 2 and
                  3 cities across India for their clients and have leased bare
                  shell/semi furnished properties for this.
                </p>
                <p className="money_para">
                  BRH Infra LLP creates professional workspaces in these
                  properties and leases back to Mikro Grafeio as fully fitted
                  out, managed workspaces.
                </p>
                <p className="money_para">
                  BRH Infra seeks investments from external individuals as loan
                  at agreed ROI, to fund the furnishing of these workspaces.
                </p>
                <p className="money_para">
                  BRH INFRA pays the agreed monthly returns to the fund investor
                  from the lease income received from Mikro Grafeio.
                </p>
              </div>
              <div
                className="col-12 col-md-6"
                style={{ alignSelf: "center", marginLeft: "auto" }}
              >
                <img
                  className="img-fluid"
                  alt="badge"
                  src={require("../../assets/projects/moneyTrail.jpg")}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="moneyTrailContainer ">
          <div className="p-5">
            <h4 className="fw-bold fs-2 orange text-center pb-3">
              HOW DOES IT WORK?
            </h4>
            <h4 className="text-white text-center p-1 m-2">
              YOUR INVESTMENT WILL FINANCE THE CAPEX FOR OFFICE INTERIOR WORKS
              OF REPUTED CLIENTS OF MIKRO GRAFEIO.
            </h4>
            <Row className="justify-content-between align-items-center">
              <Col sm={12} md={6} lg={6} xl={6}>
                <h6 className=" fs-4 fw-bold pb-3 orange">THE MONEY TRAIL</h6>
                <h6 className="fs-5 text-white pb-2">
                  Mikro Grafeio has huge requirement of workspaces in Tier 2 and
                  3 cities across India for their clients and have leased bare
                  shell/semi furnished properties for this.
                </h6>
                <h6 className="fs-5 text-white pb-2">
                  BRH Infra LLP creates professional workspaces in these
                  properties and leases back to Mikro Grafeio as fully fitted
                  out, managed workspaces.
                </h6>

                <h6 className="fs-5 text-white pb-2">
                  BRH Infra seeks investments from external individuals as loan
                  at agreed ROI, to fund the furnishing of these workspaces.
                </h6>

                <h6 className="fs-5 text-white pb-2">
                  BRH INFRA pays the agreed monthly returns to the fund investor
                  from the lease income received from Mikro Grafeio.
                </h6>
              </Col>

              <Col sm={12} md={6} lg={6} xl={6}>
                <img
                  width={250}
                  className="workspaceImage"
                  alt="badge"
                  src={require("../../assets/projects/moneyTrail.jpg")}
                />
              </Col>
            </Row>
          </div>
        </div> */}
      </Fade>
      <Fade big cascade>
        <div className="container_sample">
          <div className="container">
            <Row className="section_sample">
              <Col sm={12} md={5} className="">
                <h3 className="sample_heading_main sample_desktop_gap">
                  THE OPPORTUNITY
                </h3>
                <p className="sample_subHeading">
                  Tremendous growth prospects through investment in commercial
                  real estate through a reliable platform offering convenience,
                  transparency and end-to-end service solutions.
                </p>
                <h3 className="sample_heading_main ">THE ADVANTAGE</h3>
                <ul className="sample_list">
                  <li>Assured returns</li>
                  <li>Steady cash flow</li>
                  <li>Return of Capital after the term</li>
                  <li>Secured through Partnership & Investment Agreements</li>
                  <li>More returns than Fixed Deposit</li>
                  <li>Complete transparency</li>
                  <li>Online Dashboard access to monitor the investment</li>
                </ul>
              </Col>
              <Col md={1}></Col>
              <Col sm={12} md={6} className="">
                <h3 className="sample_heading_main sample_desktop_gap">
                  SAMPLE CALCULATION
                </h3>
                <table className="table table-bordered border-warning sample_table table_margin_from_tablet">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>Proposed Investment</td>
                      <td>INR 20,00,000</td>
                    </tr>
                    <tr>
                      <td>ROI Rate p.a</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td>Investment Term</td>
                      <td>48 Months</td>
                    </tr>
                    <tr>
                      <td>Monthly ROI Amount</td>
                      <td>INR 20,000</td>
                    </tr>
                    <tr>
                      <td>Total ROI Amount</td>
                      <td>INR 9,60,000</td>
                    </tr>
                    <tr>
                      <td>Residual amount to be returned after the Term</td>
                      <td>INR 20,00,000</td>
                    </tr>
                    <tr>
                      <td>Total amount repaid</td>
                      <td>INR 29,60,000</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
        {/* <div className="sampleCalContainer">
          <div className="p-5">
            <Row>
              <Col sm={12} md={6} lg={6} xl={6}>
                <h4 className="fw-bold fs-2 orange ">THE OPPORTUNITY</h4>

                <h6 className="fs-5">
                  Tremendous growth prospects through investment in commercial
                  real estate through a reliable platform offering convenience,
                  transparency and end-to-end service solutions.
                </h6>
                <h4 className="fw-bold fs-2 orange ">THE ADVANTAGE</h4>

                <ul className="fs-5">
                  <li>Assured returns</li>
                  <li>Steady cash flow</li>
                  <li>Return of Capital after the term</li>
                  <li>Secured through Partnership & Investment Agreements</li>
                  <li>More returns than Fixed Deposit</li>
                  <li>Complete transparency</li>
                  <li>Online Dashboard access to monitor the investment</li>
                </ul>
              </Col>
              <Col sm={12} md={6} lg={6} xl={6}>
                <h4 className="fw-bold fs-2 orange ">SAMPLE CALCULATION</h4>

                <h6 className="fs-5">
                  <table className="table table-bordered border-warning">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>ProposedInvestment</td>
                        <td>INR 20,00,000</td>
                      </tr>
                      <tr>
                        <td>ROI Rate p.a</td>
                        <td>12%</td>
                      </tr>
                      <tr>
                        <td>Investment Term</td>
                        <td>48 Months</td>
                      </tr>
                      <tr>
                        <td>Monthly ROI Amount</td>
                        <td>INR 20,000</td>
                      </tr>
                      <tr>
                        <td>Total ROI Amount</td>
                        <td>INR 9,60,000</td>
                      </tr>
                      <tr>
                        <td>Residual amount to returned after the Term</td>
                        <td>INR 20,00,000</td>
                      </tr>
                      <tr>
                        <td>Total amount repaid</td>
                        <td>INR 29,60,000</td>
                      </tr>
                    </tbody>
                  </table>
                </h6>
              </Col>
            </Row>
          </div>
        </div> */}
      </Fade>
    </>
  );
};

export default LandingScreen;
