import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { postMethod } from "../../../API_SERVICES/ApiServices";
import FormComponent from "../FormComponent";
import { onNextPage } from "./investRegisterSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";
import { Fade, Slide } from "react-reveal";

const Payment = ({ onPrevious }) => {
  const MySwal = withReactContent(Swal);

  const dispatch = useDispatch();
  const location = useLocation();
  console.log("Location", location);
  const [otpSent, setOtpSent] = useState(null);
  const [otpErr, setOtpErr] = useState(null);
  const [checkedDetails, setCheckedDetails] = useState(false);
  const [OTP, setOTP] = useState("");
  const formDetails = [
    {
      id: 1,
      formLabel: (
        <span>
          <span className="mdi mdi-information-outline text-warning"></span>
          Enter OTP
        </span>
      ),
      type: "number",
      placeHolder: "Enter OTP",
      required: true,
      error: otpErr,
    },
  ];

  const user = JSON.parse(sessionStorage.getItem("user"));
  const nominee = JSON.parse(sessionStorage.getItem("nominee"));
  const invested = JSON.parse(sessionStorage.getItem("investmentDetails"));
  const investment = JSON.parse(sessionStorage.getItem("investment"));
  const bank = JSON.parse(sessionStorage.getItem("bank"));

  const proceedToLogin = () => {
    postMethod("api/user-otp-verification", {
      user_id: user?.id,
      otp: OTP,
    })
      .then((res) => {
        if (res?.data?.status === true) {
          setOtpSent(res?.data?.message);
          MySwal.fire({
            title: <strong>success</strong>,
            html: <i>Investment Done Successfully</i>,
            showConfirmButton: true,
            confirmButtonColor: "#fc9200",
            icon: "success",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/";
            }
          });

          console.log(res);
        } else if (res?.data?.status === false) {
          MySwal.fire({
            html: <i>{res?.data?.message}</i>,
            showConfirmButton: true,
            confirmButtonText: "Resend OTP",
            confirmButtonColor: "#fc9200",
            icon: "warning",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              postMethod("api/user-authenticate", {
                pan_card_number: user?.pan_card_number,
              }).then((res) => {
                if (res?.data?.status === true) {
                  sessionStorage.removeItem("investmentDetails");
                  sessionStorage.removeItem("bank");
                  sessionStorage.removeItem("nominee");
                  setOtpSent(res?.data?.message);
                  MySwal.fire({
                    title: <strong>OTP Received</strong>,

                    showConfirmButton: true,
                    confirmButtonColor: "#fc9200",
                    icon: "success",
                    allowOutsideClick: false,
                    timer: 5000,
                  });

                  console.log(res);
                }
              });
            }
          });
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        setOtpErr(err?.response?.data?.message);
      });
  };

  const previousPage = () => {
    dispatch(onNextPage("reviewPay"));
    onPrevious("reviewPay");
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setOTP(value);
  };
  return (
    <Fade>
      <Container>
        <Row className="d-flex flex-column justify-content-center align-items-center">
          <small
            style={{ width: "400px" }}
            className=" text-center text-bg-warning mb-2"
          >
            Your OTP has been sent to your registered email address
          </small>
          {formDetails.map((detail, index) => (
            <Col className="text-center diii" xs={12} sm={12} md={6} lg={4}>
              <FormComponent
                titleInfo={detail?.titleInfo}
                key={index}
                name={detail?.name}
                info={detail?.info}
                formLabel={detail?.formLabel}
                type={detail?.type}
                placeholder={detail?.placeHolder}
                options={detail?.option}
                required={detail?.required}
                regex={detail?.regex}
                isUpperCase={detail?.isUpperCase}
                onHandleChange={onHandleChange}
                validations={detail?.error}
              />
              {otpSent && <span className="text-success">{otpSent}</span>}
            </Col>
          ))}
        </Row>
        <p className=" mt-1 mb-1">
          <span className="fw-bold"> Note:</span> Remittance should be done only
          from NRO or Resident Account. No remittance from NRE or overseas
          account permitted.
        </p>

        <div className="buttonGroup mb-3">
          <Button
            className="d-flex justify-content-center align-items-center commonBtn"
            type="button"
            onClick={previousPage}
            variant="outline-warning rounded-0"
          >
            <span className="mdi mdi-chevron-left commonIcon"></span>
            Previous
          </Button>
          <Button
            type="button"
            className="d-flex justify-content-center align-items-center commonBtn"
            onClick={proceedToLogin}
            variant="warning rounded-0"
          >
            Submit
            <span className="mdi mdi-chevron-right commonIcon"></span>
          </Button>
        </div>
      </Container>
    </Fade>
  );
};

export default Payment;
