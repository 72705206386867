import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pulse from "react-reveal/Pulse";
import { Bounce, Fade, Slide } from "react-reveal";

const Faqs = () => {
  const [brhToggle, setBrhToggle] = useState(false);
  const [brhToggleId, setBrhToggleId] = useState(null);
  const [investmentToggle, setInvestmentToggle] = useState(false);
  const [investmentToggleId, setInvestmentToggleId] = useState(null);
  const [taxToggle, setTaxToggle] = useState(false);
  const [taxToggleId, setTaxToggleId] = useState(null);
  const InvestmentRelated = () => (
    <div className="d-flex flex-column">
      <p>
        The Investment will be structured through BRH LLP through a
        Supplementary partnership deed and loan agreement. The investors would
        need to sign the aforesaid agreement and provide the following documents
        for KYC purposes:
      </p>
      <ol>
        <li>A copy of your PAN Card.</li>
        <li>Address Proof such as Aadhaar, Drivers License, or Passport.</li>
        <li>Bank statement or a cancelled cheque leaf with the name printed</li>
      </ol>
      <span>The steps for completing the investment process are as below:</span>
      <ul>
        <li>You share your KYC documents (Id proof, PAN, etc.)</li>
        <li>
          Transfer a token advance of Rs. 100/- for creating of supplementary
          partnership deed
        </li>
        <li>
          Investor is added as a partner by executing the supplementary
          partnership deed
        </li>
        <li>Transfer the investment amount on executing of deed</li>
        <li>We share a loan agreement for the investment amount.</li>
      </ul>
    </div>
  );

  const [aboutBrh, setAboutBrh] = useState([
    {
      question: "What is BRH Infra?",
      answer:
        "BRH Infra is a special purpose vehicle (SPV) incorporated under the Limited Liability Partnership Act, of 2008. The SPV shall invest/acquire assets for subsequent subleasing to Mikro Grafeio.",
      open: true,
    },
    {
      question: "How does BRH Infra Operate?",
      answer:
        "BRH acquires the asset (typically for setting up interiors, furniture & Amp; Office equipment) from the investment raised. Such assets are subleased to Mikro Grafeio.",
      open: false,
    },
    {
      question: "How secure is the investment made to BRH Infra?",
      answer:
        "The assets which are subleased to Mikro Grafeio are ideally backed by leasing contracts secured by Mikro Grafeio. Further, the investment is backed by a loan agreement between BRH Infra and the investor obligating to pay a fixed amount of interest and repayment of principal at the end of the tenure. All the investments are backed with a 100% Principal Guarantee by promoters.",
      open: false,
    },
    {
      question: "How is BRH Infra Regulated?",
      answer:
        "All our investments are done via an SPV which is registered and complaint with the Registrar of Companies (ROC), Companies Act, and MCA.",
      open: false,
    },
    {
      question:
        "What are the reporting standards and disclosure provided to investors against the investment?",
      answer:
        "BRH Infra provides access to the investor dashboard to track the monthly disbursal of interest, outstanding principal amount, TDS certificates, and all the documents executed at the time of investment.",
      open: false,
    },
  ]);
  const [aboutInvestment, setAboutInvestment] = useState([
    {
      id: 1,
      question: "What is the minimum investment?",
      answer:
        "The minimum investment amount is Rs.10,00,000 (TEN Lakhs Indian Rupees) and the maximum amount is Rs.1,50,00,000 (ONE CRORE AND FIFTY Lakhs Indian Rupees) per investor.",
      open: false,
    },
    {
      id: 2,
      question: "What is the investment process?",
      answer: <InvestmentRelated />,
      open: false,
    },
    {
      id: 3,
      question: "How to invest?",
      answer:
        "The investment amount should be transferred from the domestic Indian account to the escrow account of BRH Infra (NEFT, RTGS, Cheque). Further, NRI can invest only from their NRO account.",
      open: false,
    },
    {
      id: 4,
      question: "Can I transfer the transaction amount from an NRE account?",
      answer:
        "No. As per the current statutory framework, NRI investors can only transfer the transaction amount from an NRO account.",
      open: false,
    },
    {
      id: 5,
      question: "When and how will I get my returns?",
      answer:
        "The investor will be provided a return in the form of interest at the rate of 12% p.a. The interest is disbursed on the monthly basis on or before the 7 th of every calendar month.The principal is repaid to the investor at the end of the tenure.",
      open: false,
    },
    {
      id: 6,
      question:
        "What is the tenure of the investment? How can I exit from an investment before the tenure?",
      answer:
        "The investment is for a period of 4 years (48 months). However, an investor can withdraw the principal after a lock-in period of 2 years. Such investors can be withdrawn by providing a 3 months’ notice period.",
      open: false,
    },
    {
      id: 7,
      question:
        "Is there any fee or recovery if I intend to exit after the lock-in period?",
      answer:
        "Yes, we charge a fee of 1.5% +GST of the principal amount outstanding at the time of exit.",
      open: false,
    },
    {
      id: 8,
      question: "Is my investment linked to a particular asset or property?",
      answer:
        "No. The investment is raised based on the funding requirement of the various projects in the pipeline. Unlike fractional ownership, the investment is not tied up to a particular project.",
      open: false,
    },
  ]);
  const [aboutTax, setAboutTax] = useState([
    {
      question: "What is the tax implication on my investment?",
      answer:
        "Monthly distributions to investors are made in the form of 'interest' and are accordingly taxable in the hands of the investor as per his/her income tax slab rate.",
      open: true,
    },
    {
      question: "Are there any tax deductions on monthly distribution?",
      answer:
        "Yes, the tax shall be deducted as source as mandated by the Income-tax Act, 1961 at the time of monthly income disbursement. Further, TDS Certificate will be issued every quarter by BRH Infra. Such deductions shall be reflected in the 26AS of the investor. The current notified rate of interest is at 10% under 194A of the Income tax act, 1961.",
      open: false,
    },
    {
      question: "What are the tax documents which I will receive?",
      answer:
        "The Form – 16AS (TDS certificate) shall be shared with investors on a quarterly basis. For any other documentation in accordance with the law can be provided based on the request to dedicated BRH Infra investment manager.",
      open: false,
    },
  ]);

  const toggleFAQ = (id, index, option) => {
    setBrhToggleId(index);
    setAboutBrh(
      aboutBrh.map((faq, i) => {
        if (faq?.id === id) {
          if (option === "close") {
            console.log("FQ1", faq?.id, id, option, faq);
            faq.open = !faq.open;
            setBrhToggle(false);
          }
          if (option === "open") {
            console.log("FQ2", faq?.id, id, option, faq?.open);
            faq.open = !faq.open;
            setBrhToggle(true);
          }
        }

        return faq;
      })
    );
  };

  const toggleFAQ1 = (id, index, option) => {
    setInvestmentToggleId(index);
    setAboutInvestment(
      aboutInvestment.map((faq, i) => {
        if (faq?.id === id) {
          if (option === "close") {
            console.log("FQ1", faq?.id, id, option, faq);
            faq.open = !faq.open;
            setInvestmentToggle(false);
          }
          if (option === "open") {
            console.log("FQ2", faq?.id, id, option, faq?.open);
            faq.open = !faq.open;
            setInvestmentToggle(true);
          }
        }

        return faq;
      })
    );
  };

  const toggleFAQ2 = (id, index, option) => {
    setTaxToggleId(index);
    setAboutTax(
      aboutTax.map((faq, i) => {
        if (faq?.id === id) {
          if (option === "close") {
            console.log("FQ1", faq?.id, id, option, faq);
            faq.open = !faq.open;
            setTaxToggle(false);
          }
          if (option === "open") {
            console.log("FQ2", faq?.id, id, option, faq?.open);
            faq.open = !faq.open;
            setTaxToggle(true);
          }
        }

        return faq;
      })
    );
  };

  const FAQ = ({ faq, index, toggleFAQ }) => {
    return (
      <div
        className={"faq " + (faq.open ? "open" : "")}
        key={index}
        onClick={() => toggleFAQ(index)}
      >
        <h6 className="faq-question">{faq.question}</h6>
        <h6 className="faq-answer">{faq.answer}</h6>
      </div>
    );
  };

  return (
    <Fade>
      <div className="faqsContainer">
        <div className="d-flex align-items-center flexi ">
          <div className="bgDarkContainer align-items-center border-5 text-end">
            <h4 className="faqHeading ">
              If you're new to
              <br /> investing with us,
              <br /> you probably have a<br /> lot of questions,
              <br /> Let us help you...
            </h4>
            <button disabled className="faqButton rounded-0">
              Frequently Asked Questions
            </button>
          </div>

          <div className="fqCont">
            <img
              className="faqImage "
              alt="faq"
              width={750}
              src={require("../../assets/projects/faq.png")}
            />
            <span className="faqMobileHeading">
              If You're new to investing with us, <br /> you probably have a lot
              of questions,
              <br /> Let us help you...
            </span>
          </div>
        </div>

        <Container className="faqSection ">
          <h6 className="faqButton2">General - About BRH</h6>
          <hr className="faqLine" />

          {aboutBrh?.map((brh, index) => (
            <>
              {brhToggle && brhToggleId === index ? (
                <div
                  role="button"
                  onClick={() => toggleFAQ(brh?.id, index, "close")}
                  className="d-flex my-1"
                >
                  {brhToggle && brhToggleId === index ? (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ(brh?.id, index, "close")}
                    >
                      -
                    </span>
                  ) : (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ(brh?.id, index, "open")}
                    >
                      +
                    </span>
                  )}

                  <span className="faqQuestion">
                    {index + 1}. {brh?.question}
                    {brhToggle && brhToggleId === index && (
                      <>
                        <hr />
                        <p className="faqAnswer">{brh?.answer}</p>
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <div
                  role="button"
                  onClick={() => toggleFAQ(brh?.id, index, "open")}
                  className="d-flex my-1"
                >
                  {brhToggle && brhToggleId === index ? (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ(brh?.id, index, "close")}
                    >
                      -
                    </span>
                  ) : (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ(brh?.id, index, "open")}
                    >
                      +
                    </span>
                  )}

                  <span className="faqQuestion">
                    {index + 1}. {brh?.question}
                    {brhToggle && brhToggleId === index && (
                      <>
                        <hr />
                        <p className="faqAnswer">{brh?.answer}</p>
                      </>
                    )}
                  </span>
                </div>
              )}

              {/* <div className="d-flex my-1">
                {brhToggle && brhToggleId === index ? (
                  <span
                    className="faqToggle"
                    onClick={() => toggleFAQ(brh?.id, index, "close")}
                  >
                    -
                  </span>
                ) : (
                  <span
                    className="faqToggle"
                    onClick={() => toggleFAQ(brh?.id, index, "open")}
                  >
                    +
                  </span>
                )}

                <span className="faqQuestion">
                  {index + 1}. {brh?.question}
                  {brhToggle && brhToggleId === index && (
                    <>
                      <hr />
                      <p className="faqAnswer">{brh?.answer}</p>
                    </>
                  )}
                </span>
              </div> */}
            </>
          ))}
          <h6 className="faqButton2 mt-5">Investment Related</h6>
          <hr className="faqLine" />

          {aboutInvestment?.map((brh, index) => (
            <>
              {investmentToggle && investmentToggleId === index ? (
                <div
                  role="button"
                  onClick={() => toggleFAQ1(brh?.id, index, "close")}
                  className="d-flex my-1"
                >
                  {investmentToggle && investmentToggleId === index ? (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ1(brh?.id, index, "close")}
                    >
                      -
                    </span>
                  ) : (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ1(brh?.id, index, "open")}
                    >
                      +
                    </span>
                  )}

                  <span className="faqQuestion">
                    {index + 1}. {brh?.question}
                    {investmentToggle && investmentToggleId === index && (
                      <>
                        <hr />
                        <p className="faqAnswer">{brh?.answer}</p>
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <div
                  role="button"
                  onClick={() => toggleFAQ1(brh?.id, index, "open")}
                  className="d-flex my-1"
                >
                  {investmentToggle && investmentToggleId === index ? (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ1(brh?.id, index, "close")}
                    >
                      -
                    </span>
                  ) : (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ1(brh?.id, index, "open")}
                    >
                      +
                    </span>
                  )}

                  <span className="faqQuestion">
                    {index + 1}. {brh?.question}
                    {investmentToggle && investmentToggleId === index && (
                      <>
                        <hr />
                        <p className="faqAnswer">{brh?.answer}</p>
                      </>
                    )}
                  </span>
                </div>
              )}
            </>
          ))}
          <h6 className="faqButton2 mt-5">Taxation</h6>
          <hr className="faqLine" />

          {aboutTax?.map((brh, index) => (
            <>
              {taxToggle && taxToggleId === index ? (
                <div
                  role="button"
                  onClick={() => toggleFAQ2(brh?.id, index, "close")}
                  className="d-flex my-1"
                >
                  {taxToggle && taxToggleId === index ? (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ2(brh?.id, index, "close")}
                    >
                      -
                    </span>
                  ) : (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ2(brh?.id, index, "open")}
                    >
                      +
                    </span>
                  )}

                  <span className="faqQuestion">
                    {index + 1}. {brh?.question}
                    {taxToggle && taxToggleId === index && (
                      <>
                        <hr />
                        <p className="faqAnswer">{brh?.answer}</p>
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <div
                  role="button"
                  onClick={() => toggleFAQ2(brh?.id, index, "open")}
                  className="d-flex my-1"
                >
                  {taxToggle && taxToggleId === index ? (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ2(brh?.id, index, "close")}
                    >
                      -
                    </span>
                  ) : (
                    <span
                      className="faqToggle"
                      onClick={() => toggleFAQ2(brh?.id, index, "open")}
                    >
                      +
                    </span>
                  )}

                  <span className="faqQuestion">
                    {index + 1}. {brh?.question}
                    {taxToggle && taxToggleId === index && (
                      <>
                        <hr />
                        <p className="faqAnswer">{brh?.answer}</p>
                      </>
                    )}
                  </span>
                </div>
              )}
            </>
          ))}
        </Container>
      </div>
    </Fade>
  );
};

export default Faqs;
