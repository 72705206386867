import { configureStore } from "@reduxjs/toolkit";
import storedSates from "../components/Homepage/InvestRegisterSection/investRegisterSlice";
import landingScreenStates from "../components/Homepage/LandingPageSlice";

const store = configureStore({
  reducer: {
    StoredState: storedSates,
    landingScreenStates: landingScreenStates,
  },
});

export default store;
