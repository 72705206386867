import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "",
  investmentDetails: {},
  nomineeDetails: {},
  bankDetails: {},
  addressDetails: {},
  personalDetails: {},
};

export const slices = createSlice({
  name: "selectedTab",
  initialState,
  reducers: {
    onNextPage: (state, action) => {
      state.activeTab = action.payload;
    },
    onInvestmentDetails: (state, action) => {
      state.investmentDetails = action.payload;
    },
    onNomineeDetails: (state, action) => {
      state.nomineeDetails = action.payload;
    },
    onBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    onAddressDetails: (state, action) => {
      state.addressDetails = action.payload;
    },
    onPersonalDetails: (state, action) => {
      console.log("Actions", action);
      state.personalDetails = action.payload;
    },
  },
});

export const {
  onNextPage,
  onInvestmentDetails,
  onNomineeDetails,
  onBankDetails,
  onAddressDetails,
  onPersonalDetails,
} = slices.actions;
export default slices.reducer;
