import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  investmentPeriod: [],
  profitPaymentMode: [],
  interestRate: [],
  countriesData: [],
};

export const slices = createSlice({
  name: "landingScreenTabs",
  initialState,
  reducers: {
    investmentPeriodDetails: (state, action) => {
      state.investmentPeriod = action.payload;
    },
    profitPaymentDetails: (state, action) => {
      state.profitPaymentMode = action.payload;
    },
    interestRateDetails: (state, action) => {
      state.interestRate = action.payload;
    },
    countryDetails: (state, action) => {
      state.countriesData = action.payload;
    },
  },
});

export const {
  investmentPeriodDetails,
  profitPaymentDetails,
  interestRateDetails,
  countryDetails,
} = slices.actions;
export default slices.reducer;
