import React, { useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import toast from "react-simple-toasts";
import {
  getMethod,
  postMethod,
  updateMethod,
} from "../../../API_SERVICES/ApiServices";
import FormComponent from "../FormComponent";
import { MdModeEditOutline, MdDelete, MdDeleteOutline } from "react-icons/md";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect } from "react";

const Profile = () => {
  const dispatch = useDispatch();

  const [personalData, setPersonalData] = useState({
    name: null,
    mobile_number: null,
    dob: null,
    gender: null,
    pan_card_number: null,
    aadhar_card_number: null,
    email: null,
    profile_image_file: null,

    pan_card_file: null,
    aadhar_card_file: null,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const location = useLocation();
  console.log("loca", typeof personalData?.profile_image_file === "string");

  const investedDetails = useSelector((state) => state?.storedSates);
  const personalInformation = JSON.parse(sessionStorage.getItem("user"));

  const formDetails = [
    {
      id: 1,
      formLabel: "Name (as it appears on PAN Card)",
      type: "text",
      placeHolder: "Name",
      required: true,
      name: "name",
      isUpperCase: true,
      titleInfo: "Enter name as per PAN Card",
      error: errors.name,
      value: personalData?.name,
      readonly: !isEdit ? true : false,
    },
    {
      id: 2,
      formLabel: "DOB (DD/MM/YYYY)",
      type: "date",
      placeHolder: "DD/MM/YYYY",
      required: true,
      name: "dob",
      titleInfo: "Enter Date of Birth",
      error: errors.dob,
      value: personalData?.dob,
      readonly: !isEdit ? true : false,
    },
    {
      id: 3,
      formLabel: "Gender",
      type: "text",
      placeHolder: "Gender",
      required: true,
      name: "gender",

      titleInfo: "Select Gender",
      error: errors.gender,
      readonly: true,
      value: personalData?.gender,
    },
    {
      id: 4,
      formLabel: "Mobile Number",
      type: "number",
      placeHolder: "Mobile No.",
      required: true,
      name: "mobile_number",
      titleInfo: "Enter Mobile Number",
      error: errors.mobile_number,
      value: personalData?.mobile_number,
      readonly: !isEdit ? true : false,
    },
    {
      id: 5,
      formLabel: "PAN Card Number",
      type: "text",
      placeHolder: "PAN Number",
      required: true,
      name: "pan_card_number",

      isUpperCase: true,
      titleInfo: "Enter PAN Card Number",
      error: errors.pan_card_number,
      value: personalData?.pan_card_number,
      readonly: !isEdit ? true : false,
    },
    {
      id: 6,
      formLabel: "Aaadhar Number",
      type: "text",
      placeHolder: "Aaadhar Number",
      required: true,
      name: "aadhar_card_number",
      titleInfo: "Enter Adhaar Number",
      error: errors.aadhar_card_number,
      value: personalData?.aadhar_card_number,
      readonly: !isEdit ? true : false,
    },
    {
      id: 7,
      formLabel: "Email",
      type: "email",
      placeHolder: "Email",
      required: true,
      name: "email",
      titleInfo: "Enter Email",
      error: errors.email,
      value: personalData?.email,
      readonly: !isEdit ? true : false,
    },
    {
      id: 8,
      type: !isEdit ? "view" : "file",
      name: "profile_image_file",
      formLabel: "Profile Photo",
      hrefLink:
        typeof personalData?.profile_image_file === "string"
          ? `https://brhfiles.s3.ap-south-1.amazonaws.com/${personalData?.profile_image_file}`
          : personalData?.gender === "MALE" || personalData?.gender === "Male"
          ? require("../../../assets/images/MALEPROFILE.jpg")
          : require("../../../assets/images/FEMALE1.jpg"),
      edit: isEdit,
      imageUrl:
        typeof personalData?.profile_image_file === "string" ||
        personalData?.profile_image_file === null
          ? null
          : URL.createObjectURL(personalData?.profile_image_file),
    },
    {
      id: 9,
      type: !isEdit ? "view" : "file",
      name: "pan_card_file",
      formLabel: " PAN Card",
      hrefLink:
        typeof personalData?.pan_card_file === "string" &&
        "https://brhfiles.s3.ap-south-1.amazonaws.com/" +
          personalData?.pan_card_file,
      edit: isEdit,
      imageUrl:
        typeof personalData.pan_card_file === "string" ||
        personalData.pan_card_file === null
          ? null
          : URL.createObjectURL(personalData.pan_card_file),
    },
    {
      id: 10,
      type: !isEdit ? "view" : "file",
      edit: isEdit,
      imageUrl:
        typeof personalData.aadhar_card_file === "string" ||
        personalData.aadhar_card_file === null
          ? null
          : URL.createObjectURL(personalData.aadhar_card_file),
      name: "aadhar_card_file",
      formLabel: " Adhaar Card ",
      hrefLink:
        typeof personalData?.aadhar_card_file === "string" &&
        "https://brhfiles.s3.ap-south-1.amazonaws.com/" +
          personalData?.aadhar_card_file,
    },
  ];
  // const [formDetails, setformDetails] = useState(formdetails);
  const formRef = useRef([]);

  const onSubmit = (e) => {
    e.preventDefault();

    // const editData = {
    // name: personalData?.name ?? personalInformation?.name,
    // email: personalData?.email ?? personalInformation?.email,
    // aadhar_card_number:
    //   personalData?.aadhar_card_number ??
    //   personalInformation?.aadhar_card_number,
    // pan_card_number:
    //   personalData?.pan_card_number ?? personalInformation?.pan_card_number,
    // mobile_number:
    //   personalData?.mobile_number ?? personalInformation?.mobile_number,
    //dob: personalData?.dob ?? personalInformation?.dob,
    //gender: personalData?.gender ?? personalInformation?.gender,
    // profile_image_file:
    //   personalData?.profile_image_file ?? personalInformation?.profile_image,
    // pan_card_file:
    //   personalData?.pan_card_file ?? personalInformation?.pan_card,
    // aadhar_card_file:
    //   personalData?.aadhar_card_file ?? personalInformation?.aadhar_card,
    // status: 1,
    //is_draft: 0,
    // };

    const formData = new FormData();

    formData.append("name", personalData?.name?.toUpperCase());
    formData.append("_method", "PUT");

    formData.append("mobile_number", personalData?.mobile_number);
    personalData?.pan_card_file &&
      formData.append("pan_card_file", personalData?.pan_card_file);

    formData.append(
      "pan_card_number",
      personalData?.pan_card_number?.toUpperCase()
    );

    formData.append("dob", personalData?.dob);

    formData.append("gender", personalData?.gender);

    formData.append("aadhar_card_number", personalData?.aadhar_card_number);

    formData.append("email", personalData?.email?.toUpperCase());
    personalData?.profile_image_file &&
      formData.append(
        "profile_image_file",
        personalData?.profile_image_file
          ? personalData?.profile_image_file
          : null
      );
    personalData?.aadhar_card_file &&
      formData.append("aadhar_card_file", personalData?.aadhar_card_file);
    formData.append("status", 1);
    formData.append("is_draft", 0);
    formData.append("id", personalInformation?.id);

    console.log("editData", ...formData);

    setTimeout(() => {
      setLoading(true);
      postMethod("api/user/" + personalInformation?.id, formData)
        .then((res) => {
          if (res?.data?.status === true) {
            console.log("RES", res);
            toast(res?.data?.message, 3000);
            // setTimeout(() => {
            //   sessionStorage.clear();
            //   window.location.reload();
            // }, 3000);
            getMethod("api/user/" + personalInformation?.id).then((res) => {
              console.log("data", res.data.data);
              const data = res.data.data;
              setPersonalData((prev) => ({
                ...prev,
                name: data.name,
                mobile_number: data.mobile_number,
                dob: data.dob,
                gender: data.gender,
                pan_card_number: data.pan_card_number,
                aadhar_card_number: data.aadhar_card_number,
                email: data.email,
                profile_image_file: data.profile_image,

                pan_card_file: data.pan_card,
                aadhar_card_file: data.aadhar_card,
              }));
              setIsEdit(false);
            });

            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrors({
            ...errors,
            pan_card_number: err?.response?.data?.errors?.pan_card_number,
            mobile_number: err?.response?.data?.errors?.mobile_number,
            email: err?.response?.data?.errors?.email,
            aadhar_card_number: err?.response?.data?.errors?.aadhar_card_number,
          });
          console.log(err?.response?.data?.errors);
        });
    }, 500);
  };

  const onHandleChange = (e) => {
    const { name, value, files, result } = e.target;
    console.log(result);
    setPersonalData({ ...personalData, [name]: value });

    if (name === "name") {
      if (value.length > 4) {
        setErrors({ ...errors, name: null });
        return true;
      }
    }

    if (name === "mobile_number") {
      if (value.length !== 10) {
        setErrors({ ...errors, mobile_number: "Invalid Mobile Number" });
        return false;
      }
      setErrors({ ...errors, mobile_number: null });
      return true;
    }

    if (name === "aadhar_card_number") {
      let regex = /^\d{4}\d{4}\d{4}$/g;
      if (value.length >= 12) {
        if (regex.test(value)) {
          console.log("regex.test(aadhar_card_number)", regex.test(value));
          setErrors({ ...errors, aadhar_card_number: null });
          return true;
        }
        setErrors({
          ...errors,
          aadhar_card_number: "Invalid Adhaar Card Number",
        });
        return false;
      } else {
        setErrors({
          ...errors,
          aadhar_card_number: "Invalid Adhaar Card Number",
        });
        return false;
      }
    }

    if (name === "pan_card_number") {
      let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

      if (value.length === 10) {
        if (regex.test(value)) {
          console.log("regex.test(pan_card_number)", regex.test(value));
          setErrors({ ...errors, pan_card_number: null });
          return true;
        }
        setErrors({ ...errors, pan_card_number: "Invalid PAN Card Number" });
        return false;
      } else {
        setErrors({ ...errors, pan_card_number: "Invalid PAN Card Number" });
        return false;
      }
    }

    if (name === "profile_image_file") {
      if (files[0].size <= 2097152) {
        if (
          files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "application/pdf"
        ) {
          setPersonalData({ ...personalData, profile_image_file: files[0] });
          setErrors({ ...errors, profile_image_file: null });
        } else {
          setErrors({ ...errors, profile_image_file: "Unsupported File" });
          setPersonalData({ ...personalData, profile_image_file: null });
        }
      } else {
        setErrors({
          ...errors,
          profile_image_file: "File size should be less than 2MB",
        });
        setPersonalData({ ...personalData, profile_image_file: null });
      }
    }
    if (name === "aadhar_card_file") {
      if (files[0].size <= 2097152) {
        if (
          files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "application/pdf"
        ) {
          setPersonalData({ ...personalData, aadhar_card_file: files[0] });
          setErrors({ ...errors, aadhar_card_file: "" });
        } else {
          setErrors({ ...errors, aadhar_card_file: "Unsupported File" });
          setPersonalData({ ...personalData, aadhar_card_file: null });
        }
      } else {
        setErrors({
          ...errors,
          aadhar_card_file: "File size should be less than 2MB",
        });
        setPersonalData({ ...personalData, aadhar_card_file: null });
      }
    }
    if (name === "pan_card_file") {
      if (files[0].size <= 2097152) {
        if (
          files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "application/pdf"
        ) {
          setErrors({ ...errors, pan_card_file: "" });

          setPersonalData({ ...personalData, pan_card_file: files[0] });
        } else {
          setErrors({ ...errors, pan_card_file: "Unsupported File" });
          setPersonalData({ ...personalData, pan_card_file: null });
        }
      } else {
        setErrors({
          ...errors,
          pan_card_file: "File size should be less than 2MB",
        });
        setPersonalData({ ...personalData, pan_card_file: null });
      }
    }
  };
  console.log(personalData);
  useEffect(() => {
    getMethod("api/user/" + personalInformation?.id).then((res) => {
      console.log("data", res.data.data);
      const data = res.data.data;
      setPersonalData((prev) => ({
        ...prev,
        name: data.name,
        mobile_number: data.mobile_number,
        dob: data.dob,
        gender: data.gender,
        pan_card_number: data.pan_card_number,
        aadhar_card_number: data.aadhar_card_number,
        email: data.email,
        profile_image_file: data.profile_image,

        pan_card_file: data.pan_card,
        aadhar_card_file: data.aadhar_card,
      }));
    });
  }, []);
  return (
    <div className="investTab">
      <Container className=" rounded-0 mt-1">
        <form onSubmit={onSubmit}>
          <Row>
            {formDetails?.map((detail, index) =>
              detail.edit ? (
                <Col xs={12} sm={12} md={6} lg={4}>
                  <FormComponent
                    titleInfo={detail?.titleInfo}
                    key={index}
                    name={detail?.name}
                    info={detail?.info}
                    formRef={(el) => (formRef.current[index] = el)}
                    formLabel={detail?.formLabel}
                    type="file"
                    placeholder={detail?.placeHolder}
                    options={detail?.option}
                    required={detail?.required}
                    regex={detail?.regex}
                    isUpperCase={detail?.isUpperCase}
                    onHandleChange={onHandleChange}
                    validations={detail?.error}
                    value={detail?.value}
                    disabled={detail?.readonly}
                    hrefLink={detail?.hrefLink}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // position: "relative",
                      // marginTop: "30px",
                    }}
                  >
                    <img
                      src={detail.imageUrl ? detail.imageUrl : detail?.hrefLink}
                      alt={detail?.formLabel}
                      className="img-thumbnail"
                      style={{
                        width: detail.formLabel === "Profile Photo" && "80%",
                        height: "200px",
                      }}
                    />
                    {/* <div onClick={() => handleDelete(detail.id)}> */}
                    {/* <ClearIcon
                      style={{
                        color: "white",
                        cursor: "pointer",
                        fontSize: "30px",
                        position: "absolute", // Add this CSS property
                        top: "-10px", // Adjust the positioning as needed
                        right:
                          detail.formLabel === "Profile Photo"
                            ? "50px"
                            : "-10px", // Adjust the positioning as needed
                        backgroundColor: "#faa61a",
                        padding: "5px",
                        borderRadius: "30px",
                        transition: "#faa61a 0.3s",
                      }}
                      className="delete-icon"
                    /> */}
                    {/* </div> */}
                  </div>
                </Col>
              ) : (
                <Col xs={12} sm={12} md={6} lg={4}>
                  <FormComponent
                    titleInfo={detail?.titleInfo}
                    key={index}
                    name={detail?.name}
                    info={detail?.info}
                    formRef={(el) => (formRef.current[index] = el)}
                    formLabel={detail?.formLabel}
                    type={detail?.type}
                    placeholder={detail?.placeHolder}
                    options={detail?.option}
                    required={detail?.required}
                    regex={detail?.regex}
                    isUpperCase={detail?.isUpperCase}
                    onHandleChange={onHandleChange}
                    validations={detail?.error}
                    value={detail?.value}
                    disabled={detail?.readonly}
                    hrefLink={detail?.hrefLink}
                  />
                </Col>
              )
            )}
          </Row>

          {/* <div
            className="my-3 d-flex justify-content-center"
            style={{ columnGap: "2rem" }}
          >
            <button
              type="button"
              onClick={() => setIsEdit(!isEdit)}
              style={{ backgroundColor: "#fc9200" }}
              className="user_profile_button" //rounded-0 commonBtn w-50
            >
              {isEdit ? (
                "Cancel"
              ) : (
                <>
                  <MdModeEditOutline
                    style={{
                      fontSize: "16px",
                      marginRight: "5px",
                      marginLeft: "4px",
                    }}
                  />
                  <span>Edit</span>{" "}
                </>
              )}
            </button>
            {isEdit && (
              <button
                type="submit"
                style={{ backgroundColor: "#fc9200" }}
                className="user_profile_button" //rounded-0 commonBtn w-50
              >
                Save Changes
              </button>
            )}
          </div> */}
        </form>
      </Container>
    </div>
  );
};

export default Profile;
