import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <div className="faqFooter mt-4 ">
      <div className="faqFooterContent d-flex justify-content-around align-items-center">
        <div>
          <img
            width={200}
            src={require("../../assets/logos/logo.png")}
            alt="footerLogo"
          />
        </div>
        <div className="vl "></div>
        <div className="d-flex flex-column">
          <h4 className="footerHeading">Contacts</h4>
          <span className="">
            <i class="fas fa-phone-alt faIcons"></i>+91 75599 77150
          </span>
          <span className="">
            <i class="fas fa-envelope faIcons"></i>harishm@mikrografeio.com
          </span>
        </div>
        <div className="vl "></div>
        <div className="d-flex flex-column">
          <h4 className="footerHeading mb-2">Address</h4>
          <div className="d-flex align-items-center justify-content-between">
            <i class="fas fa-map-marker-alt faIcons"></i>
            <span className=" ">
              #678, || 2nd floor, Classic House, 17th Main, 6th A <br /> Cross,
              3rd Block, Koramangala, Bangalore - 560034
            </span>
          </div>
        </div>
        <div className="vl "></div>
        <div className="d-flex flex-column">
          <Link
            to="/contact-us"
            className="fw-bold text-decoration-none fs-5 text-dark"
          >
            Contact Us
          </Link>
          <Link
            to="terms-conditions"
            className="fw-bold text-decoration-none fs-5 text-dark"
          >
            Terms & Conditions
          </Link>
          <Link
            to="/faqs"
            className="fw-bold text-decoration-none fs-5 text-dark"
          >
            FAQ
          </Link>
        </div>
      </div>
    </div> */}
      <div className="container_faq">
        <div className="container-fluid footer_outer_container">
          <div className="row footer_border_box_shadow">
            <div className="col-12 col-lg-6 footer_tablet_border_bottom">
              <div className="row">
                <div
                  className="col-12 col-md-6 footer_tablet_border_right "
                  style={{ alignSelf: "center" }}
                >
                  <div className="footer_column_boder">
                    <div className="footer_logo_flex">
                      <img
                        maxWidth="200px"
                        className="img-fluid"
                        src={require("../../assets/logos/logo.png")}
                        alt="footerLogo"
                        style={{ marginBottom: "10px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 footer_desktop_width ">
                  <div className="footer_column_boder">
                    <h4 className="footer_heading ">Contacts</h4>
                    <p className="footer_text_icon">
                      <i class="fas fa-phone-alt footer_icon"></i>
                      <span>+91 75599 77150</span>
                    </p>
                    <p className="footer_text_icon">
                      <i class="fas fa-envelope footer_icon"></i>
                      harism@mikrografeio.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 footer_desktop_width footer_tablet_row_gap">
              <div className="row">
                <div className="col-12 col-md-6  footer_tablet_border_right">
                  <div className="footer_column_boder">
                    <h4 className="footer_heading">Address</h4>
                    <p className="footer_text_icon_address">
                      <i
                        class="fas fa-map-marker-alt footer_icon"
                        style={{ margin: "5px 0" }}
                      ></i>
                      #678, 2nd floor, Classic House, 17th Main, 6th A Cross,
                      3rd Block, Koramangala, Bangalore - 560034
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 footer_desktop_width">
                  <div className="footer_column_boder">
                    <div className="footer_links_outer">
                      <p className="footer_links_para">
                        <Link to="/contact-us" className="footer_links">
                          Contact Us
                        </Link>
                      </p>
                      <p className="footer_links_para">
                        <Link to="terms-conditions" className="footer_links">
                          Terms & Conditions
                        </Link>
                      </p>
                      <p className="footer_links_para">
                        <Link to="/faqs" className="footer_links">
                          FAQ
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-6">
              <div className="footer_column_boder">
                <div className="footer_logo_flex">
                  <img
                    maxWidth="200px"
                    className="img-fluid"
                    src={require("../../assets/logos/logo.png")}
                    alt="footerLogo"
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="footer_column_boder">
                <h4 className="footer_heading">Contacts</h4>
                <p className="footer_text_icon">
                  <i class="fas fa-phone-alt footer_icon"></i>
                  <span>+91 75599 77150</span>
                </p>
                <p className="footer_text_icon">
                  <i class="fas fa-envelope footer_icon"></i>harishm@mikrografeio.com
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="footer_column_boder">
                <h4 className="footer_heading">Address</h4>
                <p className="footer_text_icon_address">
                  <i class="fas fa-map-marker-alt footer_icon" style={{ margin: "5px 0" }}></i>
                  #678,  2nd floor, Classic House, 17th Main, 6th A
                  Cross, 3rd Block, Koramangala, Bangalore - 560034
                </p>

              </div>

            </div>
            <div className="col-12 col-md-6">
              <div className="footer_column_boder">
                <div className="footer_links_outer">

                  <p className="footer_links_para">
                    <Link
                      to="/contact-us"
                      className="footer_links"
                    >
                      Contact Us
                    </Link>
                  </p>
                  <p className="footer_links_para">
                    <Link
                      to="terms-conditions"
                      className="footer_links"
                    >
                      Terms & Conditions
                    </Link>
                  </p>
                  <p className="footer_links_para">
                    <Link
                      to="/faqs"
                      className="footer_links"
                    >
                      FAQ
                    </Link>
                  </p>
                </div>



              </div>

            </div> */}
          </div>
          {/* <Row sm={12} md={6} lg={4} xl={3} xxl={2}>
            <div className="">

            </div>

            <div className="">

              <br />

            </div>

            <div className="">
              <div className="">

              </div>
            </div>

            <div className="">

            </div>
          </Row> */}
        </div>
      </div>
      {/* <div className="faqFooter  ">
        <Row className="footerInside">
          <Row sm={12} md={6} lg={4} xl={3} xxl={2}>
            <div className="footerImage">
              <img
                maxWidth="200px"
                className="img-fluid"
                src={require("../../assets/logos/logo.png")}
                alt="footerLogo"
              />
            </div>

            <div className="footerContactUS">
              <h4 className="footerHeading">Contacts</h4>
              <span className="footer_sub_heading">
                <i class="fas fa-phone-alt faIcons"></i>+91 75599 77150
              </span>
              <br />
              <span className="footer_sub_heading">
                <i class="fas fa-envelope faIcons"></i>harishm@mikrografeio.com
              </span>
            </div>

            <div className="footerAddress">
              <h4 className="footerHeading ">Address</h4>
              <div className="d-flex align-items-center justify-content-between">
                <i class="fas fa-map-marker-alt faIcons"></i>
                <span className=" ">
                  #678, || 2nd floor, Classic House, 17th Main, 6th A <br />{" "}
                  Cross, 3rd Block, Koramangala, Bangalore - 560034
                </span>
              </div>
            </div>

            <div className="footerInfo">
              <Link
                to="/contact-us"
                className="fw-bold text-decoration-none fs-5 text-dark"
              >
                Contact Us
              </Link>
              <br />
              <Link
                to="terms-conditions"
                className="fw-bold text-decoration-none fs-5 text-dark"
              >
                Terms & Conditions
              </Link>
              <br />
              <Link
                to="/faqs"
                className="fw-bold text-decoration-none fs-5 text-dark"
              >
                FAQ
              </Link>
            </div>
          </Row>
        </Row>
      </div> */}

      {/* <div className="faqFooter  ">
        <Row className="footerInside">
          <Row sm={12} md={6} lg={4} xl={3} xxl={2}>
            <div className="footerImage">
              <img
                width={200}
                className="imageSize"
                src={require("../../assets/logos/logo.png")}
                alt="footerLogo"
              />
            </div>

            <div className="footerContactUS">
              <h4 className="footerHeading">Contacts</h4>
              <span className="">
                <i class="fas fa-phone-alt faIcons"></i>+91 75599 77150
              </span>
              <br />
              <span className="">
                <i class="fas fa-envelope faIcons"></i>harishm@mikrografeio.com
              </span>
            </div>

            <div className="footerAddress">
              <h4 className="footerHeading mb-2">Address</h4>
              <div className="d-flex align-items-center justify-content-between">
                <i class="fas fa-map-marker-alt faIcons"></i>
                <span className=" ">
                  #678, || 2nd floor, Classic House, 17th Main, 6th A <br />{" "}
                  Cross, 3rd Block, Koramangala, Bangalore - 560034
                </span>
              </div>
            </div>

            <div className="footerInfo">
              <Link
                to="/contact-us"
                className="fw-bold text-decoration-none fs-5 text-dark"
              >
                Contact Us
              </Link>
              <br />
              <Link
                to="terms-conditions"
                className="fw-bold text-decoration-none fs-5 text-dark"
              >
                Terms & Conditions
              </Link>
              <br />
              <Link
                to="/faqs"
                className="fw-bold text-decoration-none fs-5 text-dark"
              >
                FAQ
              </Link>
            </div>
          </Row>
        </Row>
      </div> */}
    </>
  );
};

export default Footer;
